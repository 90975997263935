<template>
  <div class="slider-outer slide-mobile">
    <swiper class="swiper-option" :options="swiperOptions" ref="swiper">
      <swiper-slide
        v-for="data in swiperData"
        :key="data.text"
        :class="
          value == data.key
            ? 'active-unit rounded swiper-shadow slide-size curserPointer icon-min slider-width'
            : 'rounded swiper-shadow slide-size curserPointer  icon-min slider-width'
        "
      >
        <span
          class="slide-click"
          @click="
            () => {
              $emit('input', data.key);
            }
          "
        ></span>
        <Units v-if="data.icon === 'Units'" class="menu-icon" />
        <Events v-else-if="data.icon === 'Events'" class="menu-icon" />
        <Trips v-else-if="data.icon === 'Trips'" class="menu-icon trips" />
        <Services v-else-if="data.icon === 'Services'" class="menu-icon" />
        <Videos v-else-if="data.icon === 'Videos'" class="menu-icon" />
        <Activity v-else-if="data.icon === 'Activity'" class="menu-icon" />
        <History v-else-if="data.icon === 'History'" class="menu-icon" />
        <div class="swiper-text mtop-1">
          {{ data.text }}
        </div>
      </swiper-slide>
    </swiper>
    <div slot="button-next" class="swiper-button-next swip-nxt" />
    <div slot="button-prev" class="swiper-button-prev swip-prv" />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Events from "@/assets/images/unit-icon/events.svg";
import Units from "@/assets/images/unit-icon/units.svg";
import Trips from "@/assets/images/unit-icon/trips.svg";
import Videos from "@/assets/images/unit-icon/videos.svg";
import Activity from "@/assets/images/unit-icon/activity.svg";
import History from "@/assets/images/unit-icon/history.svg";
import Services from "@/assets/images/unit-icon/services.svg";
import constants from "@/utils/constants";
export default {
  components: {
    Swiper,
    SwiperSlide,
    Units,
    Events,
    Trips,
    Videos,
    Activity,
    History,
    Services
  },
  data() {
    return {
      swiperData: this.getSlides(),
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 10,
        breakpoints: {
          320: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          480: {
            slidesPerView: 4,
            spaceBetween: 10
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  props: ["value"],
  watch: {
    value(val) {
      this.updateMenu();
    }
  },
  mounted() {
    this.updateMenu();
  },
  methods: {
    getSlides() {
      let menuItems = [
        { icon: "Units", text: this.$t("unit.Units"), key: "units" }
      ];
      if (
        this.checkAbility({
          action: constants.PERMISSIONS_ACTION.SHOW_EVENTS,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      ) {
        menuItems.push({
          icon: "Events",
          text: this.$t("unit.Events"),
          key: "events"
        });
      }
      if (
        this.checkAbility({
          action: constants.PERMISSIONS_ACTION.SHOW_HISTORY,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      ) {
        menuItems.push({
          icon: "History",
          text: this.$t("unit.History"),
          key: "history"
        });
      }
      if (
        this.checkAbility({
          action: constants.PERMISSIONS_ACTION.SHOW_TRIPS,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      ) {
        menuItems.push({
          icon: "Trips",
          text: this.$t("unit.Trips"),
          key: "trips"
        });
      }
      if (
        this.checkAbility({
          action: constants.PERMISSIONS_ACTION.SHOW_TRIPS,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      ) {
        menuItems.push({
          icon: "Services",
          text: this.$t("unit.Services"),
          key: "services"
        });
      }
      if (
        this.checkAbility({
          action: constants.PERMISSIONS_ACTION.SHOW_VIDEOS,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      ) {
        menuItems.push({
          icon: "Videos",
          text: this.$t("unit.Videos"),
          key: "videos"
        });
      }
      if (
        this.checkAbility({
          action: constants.PERMISSIONS_ACTION.SHOW_ACTIVITY,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      ) {
        menuItems.push({
          icon: "Activity",
          text: this.$t("unit.Activity"),
          key: "activity"
        });
      }

      menuItems = menuItems.map((e, i) => {
        e.index = i;
        return e;
      });
      return menuItems;
    },
    updateMenu() {
      const ref = this.$refs.swiper;
      const routeToMenuMapping = {
        "unit-events": "events",
        "unit-activity": "activity",
        "unit-videos": "videos",
        "unit-trips": "trips",
        "unit-map": "units",
        "unit-history": "history",
        "unit-services": "services"
      };
      // setTimeout(() => {
      const routeName = this.$route.name;
      this.selectedMenu = routeToMenuMapping[routeName] || "units";
      const menuItems = this.getSlides();
      const menu = menuItems.find((e) => e.key === this.selectedMenu);
      ref.$el.swiper.slideTo(menu.index);
    }
  }
};
</script>
<style lang="scss">
.slider-outer {
  position: relative;
  .swiper-text.mtop-1 {
    font-size: 0.9rem;
    letter-spacing: -0.5px;
  }
  .slide-click {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
    cursor: pointer;
  }
  .swiper-container {
    padding: 10px 0px;
  }

  .swiper-option {
    max-width: calc(100% - 30px);
  }
  .swiper-container {
    position: relative;
    margin-left: 0;
    .swiper-wrapper {
      .swiper-slide {
        text-align: center;
      }
    }
  }
  .swip-nxt,
  .swip-prv {
    background-color: var(--primary);
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.2);
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: var(--white);
    &:after {
      font-size: 13px;
    }
  }
  .swip-nxt {
    top: 73px;
    left: auto;
    right: -4px;
    &:after {
      height: 11px;
      width: 5px;
    }
  }
  .swip-prv {
    top: 40px;
    left: auto;
    right: -4px;
    &:after {
      height: 15px;
      width: 10px;
    }
  }
  .slide-size {
    height: 74px;
    padding: 12px 6px 10px 6px;
    box-shadow: 0 4px 4px 0 rgba(34, 41, 47, 0.1);
    position: relative;
    .mtop-1 {
      margin-top: 3px;
    }
  }
  .slide-size {
    border: 1px solid transparent;
  }
  .slide-size.active-unit,
  .slide-size:hover {
    border: 1px solid var(--primary);
  }
  .dark-layout {
    .slide-size.active-unit,
    .slide-size:hover {
      background-color: var(--dark-bg);
    }
  }
  .menu-icon {
    path {
      fill: var(--primary);
    }
    .line {
      stroke: var(--success);
    }
    .map-ic {
      fill: var(--success);
    }
  }
  .menu-icon.trips {
    circle {
      fill: var(--primary);
    }
    .white-fill {
      fill: var(--white);
    }
  }
  .icon-min {
    .menu-icon {
      min-height: 34px;
    }
  }
}
@media (max-width: 667px) {
  .slide-size:hover {
    border: 0px solid var(--primary);
    background-color: transparent;
  }
  .slide-size.active-unit {
    border: 1px solid var(--primary) !important;
  }
  .dark-layout {
    .slide-size:hover {
      background-color: transparent;
    }
  }
}
@media (max-width: 667px) {
  .swiper-container {
    right: 0% !important;
  }
  .slide-mobile {
    .slider-width {
      max-width: 88px !important;
      margin-right: 5px !important;
    }
  }
  .slide-size:hover {
    border: 0px solid var(--primary);
    background-color: transparent;
  }
  .slide-size.active-unit {
    border: 1px solid var(--primary) !important;
  }
  .dark-layout {
    .slide-size:hover {
      background-color: transparent;
    }
  }
}
</style>
