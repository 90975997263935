var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"scrollContainer",staticClass:"service-list-scroll",on:{"scroll":_vm.handleScroll}},[(_vm.showLoading == 0)?_c('div',{staticClass:"table-responsive"},[_c('b-skeleton-table',{attrs:{"rows":6,"columns":2,"table-props":{ bordered: true, striped: true }}})],1):_vm._e(),(_vm.showLoading == 1)?_c('div',{staticClass:"mt-1"},[_c('b-jumbotron',{attrs:{"lead":_vm.$t('UnitService.NoReminderText')}})],1):_vm._e(),(_vm.showLoading == 2)?_c('div',_vm._l((_vm.serviceData),function(service,index){return _c('div',{key:index,staticClass:"service-card"},[_c('div',{staticClass:"service-icons-outer"},[_c('div',{staticClass:"service-heading",on:{"click":function($event){return _vm.OnReminderView(service.service_id)}}},[_vm._v(" "+_vm._s(service.name)+" ")]),(service.services)?_c('div',{staticClass:"service-icons"},[_vm._l((service.services.slice(0, 3)),function(serviceInfo,index){return _c('div',{key:index},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:({
                title: serviceInfo.title || '',
                customClass: 'service-info'
              }),expression:"{\n                title: serviceInfo.title || '',\n                customClass: 'service-info'\n              }",modifiers:{"hover":true,"top":true}}],attrs:{"id":'circle-' + index}},[(serviceInfo.icon)?_c('b-img',{staticClass:"service-icon",attrs:{"src":serviceInfo.icon}}):_vm._e()],1)])}),(service.services.length > 3)?_c('b-badge',{staticClass:"show-more-badge",attrs:{"variant":"primary","pill":"","id":"popover-target-service"}},[_vm._v(" + "+_vm._s(service.services.length - 3)+" ")]):_vm._e(),_c('b-popover',{attrs:{"target":"popover-target-service","triggers":"hover","custom-class":"custom-popover","placement":"left"}},_vm._l((service.services.slice(3)),function(serviceInfo,index){return _c('div',{key:index},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:({
                  title: serviceInfo.title || '',
                  customClass: 'service-info'
                }),expression:"{\n                  title: serviceInfo.title || '',\n                  customClass: 'service-info'\n                }",modifiers:{"hover":true,"top":true}}],attrs:{"id":'circle-' + index}},[(serviceInfo.icon)?_c('b-img',{staticClass:"service-icon",attrs:{"src":serviceInfo.icon}}):_vm._e()],1)])}),0)],2):_vm._e()]),(service.configuration && service.configuration.length)?_c('div',_vm._l((service.configuration.filter(
            function (e) { return e.type === 'TIME'; }
          )),function(configuration,index){return _c('div',{key:index,staticClass:"info-unit-service"},[(configuration.type === 'TIME')?_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"servicedue-date"},[_c('div',[_c('feather-icon',{staticClass:"calender-icon",attrs:{"icon":"CalendarIcon","size":"16"}})],1),_c('div',{staticClass:"service-date"},[_vm._v(" "+_vm._s(configuration.value)+" ")])])]),_c('div',{staticClass:"d-flex time"},[_c('div',[_c('feather-icon',{staticClass:"calender-icon",attrs:{"icon":"ClockIcon","size":"16"}})],1),_c('div',[_vm._v(" "+_vm._s(configuration.time)+" ")])]),_c('div',{staticClass:"d-flex"},[_c('div',[_c('feather-icon',{staticClass:"calender-icon",attrs:{"icon":"BellIcon","size":"16"}})],1),_c('div',{staticClass:"due"},[_vm._v(" "+_vm._s(configuration.due)+" ")])])]):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"info-icon"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:({
            title: service.info_message || '',
            customClass: 'service-info'
          }),expression:"{\n            title: service.info_message || '',\n            customClass: 'service-info'\n          }",modifiers:{"hover":true,"top":true}}],staticClass:"text-primary"},[_c('feather-icon',{attrs:{"icon":"InfoIcon","id":"info","size":"18"}})],1)])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }