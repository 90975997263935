<template>
  <div class="unit-page-outer playback-main">
    <SideBar
      class="slide-bar"
      :playback="playback"
      :openMapClass="openMapClass"
      :closeMenu="closeMenu"
    />
    <b-row>
      <b-col
        cols="12"
        class="mb-2-0 mob-0"
        :class="sidebarToggle ? 'sm-map' : 'lg-map'"
      >
        <Loader :show="show" :isHideLogo="true" style="z-index: 1000" />
        <div class="map-outer">
          <l-map ref="unitMap" :center="latLng" :zoom="zoom"> </l-map>
        </div>
      </b-col>
    </b-row>

    <UnitInfoMenu
      v-if="unitInfoLoader || unitInfo"
      :UnitInfo="unitInfo"
      :unitInfoLoader="unitInfoLoader"
      :clearUnitInfo="clearUnitInfo"
      :sidebarToggle="sidebarToggle"
      :playback="playback"
      :onInfoMenuCloseMainModal="onInfoMenuCloseMainModal"
    ></UnitInfoMenu>
    <UnitTripDetails
      v-if="unitTripInfo"
      :unitTripInfo="unitTripInfo"
      :unitInfoLoader="unitInfoLoader"
      :clearUnitTripInfo="clearUnitTripInfo"
      :playback="playback"
      :sidebarToggle="sidebarToggle"
    ></UnitTripDetails>
    <UnitEventDetails
      v-if="eventInfo"
      :eventInfo="eventInfo"
      :sidebarToggle="sidebarToggle"
      :clearEventInfo="clearEventInfo"
    ></UnitEventDetails>
  </div>
</template>
<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import SideBar from "@/views/Unit/UnitTracking/shared/SideBar.vue";
import "@/libs/map/js/control.playback/";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { LMap } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import UnitInfoMenu from "@/views/Unit/UnitTracking/shared/UnitInfoMenu.vue";
import UnitService from "@/libs/api/unit-service";
import UnitTripDetails from "@/views/Unit/UnitTracking/shared/UnitTripDetails.vue";
import UnitEventDetails from "@/views/Unit/UnitTracking/shared/UnitEventDetails.vue";
import MapLayers from "@/libs/map/MapLayers";
import Loader from "@/layouts/components/Loader.vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import "leaflet.featuregroup.subgroup";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    LMap,
    SideBar,
    UnitInfoMenu,
    UnitTripDetails,
    Loader,
    UnitEventDetails
  },
  data() {
    return {
      playback: null,
      sidebarToggle: true,
      unitInfo: null,
      isLive: false,
      isHideEventPopup: true,
      unitTripInfo: null,
      unitTripInfoLoader: true,
      unitInfoLoader: false,
      eventInfo: null,
      latLng: [0, 0],
      zoom: 5,
      closeMenu: false,
      show: false
    };
  },
  watch: {
    sidebarToggle(val) {
      setTimeout(() => {
        if (this.$refs.unitMap) {
          this.$refs.unitMap.mapObject.invalidateSize();
        }
      }, 100);
    },
    "$store.state.mapLoader.is_loading"(val) {
      this.show = val;
    },
    "$store.state.mapLoader.is_live_unit"(val) {
      this.isLive = val;
    },
    "$store.state.mapLoader.is_popup_show"(val) {
      if (val) {
        this.clearEventInfo();
      }
    },
    "$store.state.mapLoader.is_event_popup_show"(val) {
      if (!val) {
        this.clearEventInfo();
      }
    },
    $route(to, from) {
      // this.clearUnitInfo();
      this.clearUnitTripInfo();
      this.clearEventInfo();
      if (to.name !== "unit-map") {
        this.unitInfo = null;
      }
      if (to.name === "unit-map" && from.name === "unit-history") {
        this.clearUnitInfo();
      }
    }
  },
  beforeDestroy() {
    this.playback.events.off("pointClick");
    this.playback.events.off("onLiveToggle");
    this.playback.events.off("onLive");
  },
  mounted() {
    var map = this.$refs && this.$refs.unitMap && this.$refs.unitMap.mapObject;
    new MapLayers(map);

    // mySubGroup.addTo(map);
    map.invalidateSize();
    if (
      map &&
      map.zoomControl &&
      map.zoomControl._zoomInButton &&
      map.zoomControl._zoomOutButton
    )
      map.zoomControl._zoomInButton.title = this.$t("ZoomIn") || "Zoom In";
    map.zoomControl._zoomOutButton.title = this.$t("ZoomOut") || "Zoom Out";
    this.initiateUnitPlayback(map);
    const centerEmptyMap = (latLng, zoom) => {
      const doNotCenter = this.playback && this.playback.getTrackCount() > 0;
      if (!doNotCenter) {
        this.latLng = latLng;
        this.zoom = zoom || this.zoom;
      }
    };
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          centerEmptyMap([position.coords.latitude, position.coords.longitude]);
        },
        (error) => {
          console.log(error);
          const doNotCenter =
            this.playback && this.playback.getTrackCount() > 0;
          if (!doNotCenter) {
            centerEmptyMap([0, 0], 3);
          }
        }
      );
    } else {
      centerEmptyMap([0, 0], 3);
    }
  },
  methods: {
    openMapClass(e) {
      this.sidebarToggle = e;
    },
    onInfoMenuCloseMainModal(val) {
      this.closeMenu = val;
    },
    onPointClick(data) {
      if (this.unitInfo) {
        this.playback.pause();
      }

      if (data && data.message && data.message.id && data.screen === "units") {
        this.getUnitDetails(data.message.id, data.isPointClick);
        this.clearEventInfo();
      } else if (
        data &&
        data.message &&
        (data.message.id || data.message.message_id) &&
        data.message.unitId &&
        data.screen === "history"
      ) {
        this.clearEventInfo();
        this.onClickMapHistory({
          message_id: data.message.id || data.message.message_id,
          unit_id: data.message.unitId,
          is_live: data.is_live
        });
      } else if (
        data &&
        data.message &&
        data.message.tripId &&
        data.message.unitId &&
        data.screen === "trip"
      ) {
        this.clearEventInfo();
        this.onClickUnitDetails({
          trip_id: data.message.tripId,
          unit_id: data.message.unitId,
          is_live: data.is_live
        });
      } else if (data && data.message.screen === "event") {
        if (data.message.event_name) {
          this.onClickEvent(data);
        } else {
          this.clearEventInfo();
        }
      }
    },
    onLive(data) {
      if (
        (this.$route.name === "unit-history" &&
          this.unitInfo &&
          !this.unitInfo.isLive) ||
        (!this.isLive && this.$route.name === "unit-history")
      ) {
      } else {
        if (
          this.unitInfo &&
          this.unitInfo.id &&
          this.unitInfo.id === data.unit_id
        ) {
          const UnitInfoTemp = JSON.parse(JSON.stringify(this.unitInfo));
          const index = UnitInfoTemp.devices.findIndex(
            (item) => item.id == data.device.id
          );
          if (index >= 0) {
            UnitInfoTemp.devices[index] = data.device;
          }
          if (
            UnitInfoTemp.position.device_id &&
            ((Array.isArray(UnitInfoTemp.position.device_id) &&
              UnitInfoTemp.position.device_id[0] == data.device.id) ||
              UnitInfoTemp.position.device_id == data.device.id)
          ) {
            UnitInfoTemp.position = data.position;
            UnitInfoTemp.position.device_id = data.device.id;
          }
          UnitInfoTemp.message_time = data.message_time;
          this.unitInfo.isLive = true;
          this.unitInfo = JSON.parse(JSON.stringify(UnitInfoTemp));
        }
      }
    },
    initiateUnitPlayback(map) {
      this.playback = this.playbackControl = L.control
        .playback({
          options: {},
          data: {
            units: []
          }
        })
        .addTo(map);

      this.playback.events.on("pointClick", (data) => {
        this.onPointClick(data);
      });
      this.playback.events.on("onLive", (data) => {
        this.onLive(data);
      });
      this.playback.events.on("onLiveToggle", (data) => {
        if (data) {
          this.isLive = true;
        } else {
          this.isLive = false;
        }
      });
    },
    clearUnitInfo() {
      this.unitInfo = null;
      this.unitInfoLoader = false;
      if (this.$route.name === "unit-history") {
        this.playback.stop();
      }

      this.playback.recenterMap();
    },
    clearUnitTripInfo() {
      this.unitTripInfo = null;
      this.unitInfoLoader = false;
      this.playback.stop();
      this.playback.recenterMap();
    },
    async getUnitDetails(id, isPointClick) {
      try {
        this.unitInfoLoader = true;
        let response = await new UnitService().getUnitDataV2({
          unit_id: id,
          account_id:
            this.$route.query.account_id || localStorage.getItem("ACCOUNT_ID")
        });

        if (response && response.data) {
          this.unitInfoLoader = false;
          this.unitInfo = response.data;
          this.unitInfo.isPointClick = isPointClick;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async onClickMapHistory(data) {
      try {
        if (data && data.is_clear) {
          this.clearUnitInfo();
          return;
        }
        if (!data || !data.message_id || !data.unit_id) {
          return;
        }
        this.unitInfoLoader = true;
        let response = await new UnitService().getUnitMessageDetails({
          account_id:
            this.$route.query.account_id || localStorage.getItem("ACCOUNT_ID"),
          message_id: Number(data.message_id),
          unit_id: data.unit_id
        });
        this.showLoading = false;
        this.unitInfoLoader = false;
        if (response && response.data) {
          this.unitInfo = response.data;
          this.unitInfo.showOnlyPlayback = true;
          this.unitInfo.isLive = data.is_live;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    clearEventInfo() {
      this.eventInfo = null;
    },
    async onClickEvent(data) {
      this.eventInfo = data.message;
      this.$store.commit("mapLoader/SET_EVENT_POPUP_SHOW", true);
    },
    async onClickUnitDetails(data) {
      try {
        if (data && data.is_clear) {
          this.clearUnitTripInfo();
          return;
        }
        if (!data || !data.trip_id || !data.unit_id) {
          return;
        }
        this.unitInfoLoader = true;
        let response = await new UnitService().getTripDetails({
          account_id:
            this.$route.query.account_id || localStorage.getItem("ACCOUNT_ID"),
          trip_id: data.trip_id,
          unit_id: data.unit_id
        });
        this.showLoading = false;
        if (response && response.data) {
          this.unitTripInfo = response.data;
          this.unitTripInfo.isLive = data.is_live;
          this.unitInfoLoader = false;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
.unit-map-icon {
  .b-avatar {
    .b-avatar-img {
      padding: 4px;
      img {
        object-fit: contain;
      }
    }
  }
}
@media screen and (min-width: 991px) {
  .playback-main {
    .sm-map {
      .loading {
        left: calc(50% - 185px) !important;
      }
    }
  }
  .playback-main {
    .sm-map {
      .loading {
        left: calc(50% - 185px) !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.vue2leaflet-map {
  &.leaflet-container {
    height: 600px;
    height: calc(100vh - 107px);
  }
}
.trans-bg {
  background: transparent !important;
}
.map-outer {
  width: 100%;
}
.leaflet-popup-content-wrapper {
  padding: 0px !important;
}
.leaflet-popup {
  bottom: 17px !important;
}
.unit12 {
  .b-avatar {
    .b-avatar-img {
      img {
        margin-bottom: 20px;
        position: absolute;
        padding: 5px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .vue2leaflet-map {
    &.leaflet-container {
      height: 92vh;
    }
  }
}
@media screen and (max-width: 667px) {
  .vue2leaflet-map {
    &.leaflet-container {
      height: calc(100vh - 205px);
    }
  }
}
.sm-map {
  .map-outer {
    .vue2leaflet-map.leaflet-container {
      width: calc(100% - 400px);
    }
  }
}
.lg-map {
  .map-outer {
    .vue2leaflet-map.leaflet-container {
      width: 100%;
    }
  }
}
@media screen and (max-width: 991px) {
  .sm-map {
    .map-outer {
      .vue2leaflet-map.leaflet-container {
        width: 100%;
      }
    }
  }
}
.slide-bar {
  z-index: 9;
}
.unit-page-outer {
  position: relative;
  overflow: hidden;
  .search-card {
    max-width: 100%;
  }
}
.dark-layout {
  .slide-bar {
    z-index: 9;
    .vs__selected-options input,
    input {
      &::placeholder {
        color: var(--white) !important;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: var(--white) !important;
      }
      &::-ms-input-placeholder {
        color: var(--white) !important;
      }
    }
    .reportrange-text {
      span {
        color: var(--white) !important;
      }
    }
  }
}
</style>
