<template>
  <div class="list-scroll-video" ref="scrollContainer" @scroll="handleScroll">
    <div v-if="loading == 0">
      <b-jumbotron :lead="$t('videos.NoRecordingTxt')"> </b-jumbotron>
    </div>
    <b-container v-if="loading == 1">
      <b-row>
        <b-col cols="6" :key="key" v-for="(mock, key) in new Array(8).fill()">
          <b-card no-body img-top class="skeleton-top">
            <b-skeleton-img
              animation="wave"
              card-img="top"
              aspect="16:9"
            ></b-skeleton-img>
            <b-card-body>
              <b-skeleton animation="wave" width="99%"></b-skeleton>
            </b-card-body>
          </b-card> </b-col
      ></b-row>
    </b-container>
    <div class="video-row" v-if="loading == 2">
      <div class="video-col" :key="key" v-for="(video, key) in videos">
        <div class="video-block">
          <b-img
            :src="video.poster"
            class="poster-img"
            v-img-fallback="BASE_URL + 'images/default-picture.svg'"
          />
          <div class="play-bg" @click="playVideo(video)">
            <PlayCircle class="play-btn" />
          </div>
          <div class="duration">{{ video.duration }}</div>
        </div>
        <div class="bottom-video-section">
          <div class="bottom-video-name">
            <VideosListIcon />
            <div class="channel-name" v-b-tooltip.hover.top="video.device_name">
              {{ video.device_name }}
            </div>
          </div>
          <div class="bottom-video-name">
            <feather-icon size="16" icon="CalendarIcon" />
            <div
              class="channel-name"
              v-b-tooltip.hover.top="dateTimeConvert(video.ctime)"
            >
              {{ dateTimeConvert(video.ctime) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="video-player-popup"
      centered
      modal-class="no-header-modal no-header-close player-modal"
    >
      <template #modal-title>
        <div class="d-flex align-items-center">
          <VideosListIcon />
          <span class="pl-1 player-title">{{ sensorName }}</span>
        </div>
        <!-- <div class="">
          <button variant="outline-secondary" class="live-btn">
            {{ $t("unit.Live") }} <span class="live-doth"></span>
          </button>
        </div> -->
      </template>

      <poly-video-player
        v-if="currentVideo && currentVideo.stream_url"
        :media="{ src: currentVideo.stream_url, type: 'application/x-mpegURL' }"
        :poster="video.poster || '/images/default-picture.svg'"
        :options="{
          liveui: false,
          autoplay: true
        }"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BContainer,
  BCard,
  BCardBody,
  BCardImg,
  BCardText,
  BRow,
  BCol,
  BSkeleton,
  BSkeletonImg,
  BJumbotron,
  BPagination,
  BButton,
  BImg,
  VBTooltip
} from "bootstrap-vue";
import * as Utils from "@/libs/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UnitService from "@/libs/api/unit-service";
import PolyVideoPlayer from "@/layouts/components/PolyVideoPlayer";
import ReloadVideo from "@/assets/images/icons/reload-video.svg";
import VideosListIcon from "@/assets/images/unit-icon/video-list.svg";
import PlayCircle from "@/assets/images/unit-icon/play-circle.svg";
import store from "@/store";
import constants from "@/utils/constants";
export default {
  name: "VideoGallery",
  components: {
    BImg,
    BContainer,
    BCard,
    BCardBody,
    BCardImg,
    BCardText,
    BRow,
    BCol,
    BSkeleton,
    BSkeletonImg,
    BJumbotron,
    PolyVideoPlayer,
    BPagination,
    BButton,
    VBTooltip,
    ReloadVideo,
    VideosListIcon,
    PlayCircle
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  props: {
    unitDateFilter: {
      type: Object,
      required: true
    },
    playVideoClick: {
      type: Function
    }
  },
  watch: {
    unitDateFilter: {
      deep: true,
      handler(newOptions) {
        this.videos = [];
        this.page = 1;
        this.getRecordings();
      }
    },
    "pagination.page": {
      handler() {
        this.getRecordings();
      }
    }
  },
  mounted() {
    this.getRecordings();
  },
  data() {
    return {
      BASE_URL: process.env.BASE_URL,
      currentVideo: null,
      loading: 0,
      video: [],
      videos: [],
      pagination: {
        page: 1,
        page_size: 12,
        total_count: 0
      },
      sensorName: ""
    };
  },
  methods: {
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollOffset =
        scrollContainer.scrollHeight - scrollContainer.scrollTop - 1;
      const containerHeight = scrollContainer.clientHeight;
      if (scrollOffset > containerHeight) {
        if (
          this.pagination.total_count >
          this.pagination.page * this.pagination.page_size
        ) {
          this.pagination.page++;
          this.getRecordings();
        }
      }
    },
    formatDateTime(dateTime) {
      return Utils.formatDateTime(dateTime);
    },
    dateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    playVideo(video) {
      this.currentVideo = video;
      this.playVideoClick(true);
      this.$bvModal.show("video-player-popup");
    },
    async getRecordings() {
      clearTimeout(this.getRecordingsDoTo);
      this.getRecordingsDoTo = setTimeout(() => {
        this.getRecordingsDo(true);
      }, 500);
    },
    reloadVideosList() {
      this.page = 1;
      this.getRecordingsDo(false);
    },
    async getRecordingsDo(isScroll) {
      store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
      if (
        !this.unitDateFilter ||
        !this.unitDateFilter.selectedUnit ||
        !this.unitDateFilter.account_id ||
        !this.unitDateFilter.start_time ||
        !this.unitDateFilter.end_time ||
        this.unitDateFilter.start_time === "Invalid date" ||
        this.unitDateFilter.end_time === "Invalid date"
      ) {
        store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
        return;
      }
      let filters;
      if (
        this.unitDateFilter &&
        this.unitDateFilter.start_time &&
        this.unitDateFilter.end_time &&
        (this.unitDateFilter.start_time !== "Invalid date" ||
          this.unitDateFilter.end_time !== "Invalid date")
      ) {
        filters = [
          {
            field: "time",
            start_value: this.unitDateFilter.start_time,
            end_value: this.unitDateFilter.end_time,
            operator: "between"
          }
        ];
      }
      this.loading = 1;
      let requestData = {
        page: this.pagination.page,
        page_size: this.pagination.page_size,
        filters,
        unit_id: this.unitDateFilter.selectedUnit,
        account_id: this.unitDateFilter.account_id
      };

      const response = await new UnitService().getRecordedVideos(requestData);
      if (response && response.data) {
        this.loading =
          response.data.videos &&
          response.data.videos.pagination &&
          response.data.videos.pagination.total_records > 0
            ? 2
            : 0;
        this.pagination.total_count =
          response.data.videos &&
          response.data.videos.pagination &&
          response.data.videos.pagination.total_records;
        const videoItem = response.data.videos.list;

        this.videos = isScroll ? [...this.videos, ...videoItem] : videoItem;
        response.data.videos.list.map((v) => {
          this.sensorName = v.sensor;
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.message || response.error.title,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.video-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-wrap: wrap;
  .video-block {
    width: 100%;
    height: 86px;
    border-radius: 6px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    .duration {
      position: absolute;
      bottom: 5px;
      right: 5px;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      padding: 2px 5px;
      font-size: 12px;
      border-radius: 3px;
    }
    .play-bg {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 9;
    }
  }
  .video-col {
    width: 48%;
    border: 1px solid var(--gray4);
    padding: 0 0 12px 0;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  .bottom-video-section {
    padding-left: 8px;
  }
  .bottom-video-name {
    display: flex;
    align-items: center;
    .channel-name {
      padding-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% - 18px);
      cursor: pointer;
    }
  }
}
.list-scroll-video {
  min-height: calc(100vh - 380px);
  max-height: calc(100vh - 380px);
  overflow-y: auto;
  margin-top: 15px;
}
.skeleton-top {
  .card-body {
    padding: 0px;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.poster-img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
.play-btn {
  svg,
  path {
    fill: var(--primary);
  }
}
.player-modal {
  .modal-dialog {
    min-width: 580px;
    .overlay-btn {
      min-height: 350px;
    }
  }
  .player-title {
    font-size: 14px;
  }
  .modal-body {
    padding: 0 5px 5px 5px;
    .bottom-video-name-modal {
      display: flex;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 15px;
      padding-left: 15px;
      .channel-name {
        padding-left: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 230px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.live-btn {
  border-radius: 4px;
  border: 1px solid #ed0000;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 10px 1px #e70606;
  color: #ed0000;
  position: relative;
  margin: auto;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.live-doth {
  background-color: #ed0000;
  height: 12px;
  width: 12px;
  border-radius: 12px;
  display: inline-block;
  margin-left: 8px;
}
// @media screen and (max-width: 991px) {
//   .unit-list-scroll {
//     max-height: calc(100vh - 520px);
//     min-height: calc(100vh - 520px);
//   }
//   .player-modal .modal-dialog {
//     min-width: 96%;
//   }
// }
@media screen and (max-width: 991px) {
  .player-modal .modal-dialog {
    min-width: 96%;
  }
}
@media screen and (max-width: 667px) {
  .list-scroll-video {
    max-height: calc(100vh - 420px);
    min-height: calc(100vh - 420px);
  }
}
.dark-layout .jumbotron {
  background: var(--unit-bg-dark);
}
</style>
