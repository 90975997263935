<template>
  <b-dropdown
    variant="link"
    toggle-class="p-0 unit-drop "
    no-caret
    right
    class="custom-unit-dropdown"
  >
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="28"
        class="align-left text-body"
      />
    </template>

    <b-dropdown-item
      class="unit-menu-item"
      :to="{
        name: 'unit-events',
        query: { id: unit.id, account_id: $route.query.account_id }
      }"
      v-if="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.SHOW_EVENTS,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      "
    >
      <span class="menu-icon-outer"><Events class="drop-down-icon" /></span
      ><span class="align-middle ml-51">{{ $t("UnitTrack.Events") }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      class="unit-menu-item"
      v-if="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.SHOW_HISTORY,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      "
      :to="{
        name: 'unit-history',
        query: { id: unit.id, account_id: $route.query.account_id }
      }"
    >
      <span class="menu-icon-outer"><History class="drop-down-icon" /></span
      ><span class="align-middle ml-51">{{ $t("unit.History") }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      class="unit-menu-item"
      :to="{
        name: 'unit-trips',
        query: { id: unit.id, account_id: $route.query.account_id }
      }"
      v-if="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.SHOW_TRIPS,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      "
    >
      <span class="menu-icon-outer"><Trips class="drop-down-icon trips" /></span
      ><span class="align-middle ml-51">{{ $t("UnitTrack.Trips") }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      class="unit-menu-item"
      :to="{
        name: 'maintenance-reminder-list',
        query: { unit: unit.id, account_id: $route.query.account_id }
      }"
      v-if="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.READ,
          subject: constants.PERMISSIONS_MODEL.SERVICE_REMINDERS
        })
      "
    >
      <span class="menu-icon-outer"
        ><Services class="drop-down-icon trips" /></span
      ><span class="align-middle ml-51">{{ $t("UnitTrack.Services") }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      class="unit-menu-item"
      v-if="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.SHOW_ACTIVITY,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      "
      :to="{
        name: 'unit-activity',
        query: { id: unit.id, account_id: $route.query.account_id }
      }"
    >
      <span class="menu-icon-outer"><Activity class="drop-down-icon" /></span
      ><span class="align-middle ml-51">{{ $t("UnitTrack.Activity") }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      class="unit-menu-item"
      v-if="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.SHOW_VIDEOS,
          subject: constants.PERMISSIONS_MODEL.UNITS
        })
      "
      :to="{
        name: 'unit-videos',
        query: { id: unit.id, account_id: $route.query.account_id }
      }"
    >
      <span class="menu-icon-outer"><Videos class="drop-down-icon" /></span
      ><span class="align-middle ml-51">{{ $t("UnitTrack.Videos") }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      class="unit-menu-item"
      @click="allocationUnit(unit)"
      v-if="
        isAllocatedUnit &&
        checkAbility({
          action: constants.PERMISSIONS_ACTION.UPDATE,
          subject: constants.PERMISSIONS_MODEL.UNITS
        }) &&
        !unit.archived
      "
    >
      <span class="menu-icon-outer"><Allocation class="drop-down-icon" /></span
      ><span class="align-middle ml-51">{{ $t("UnitTrack.Allocation") }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      class="unit-menu-item"
      :to="{ name: 'edit-unit', params: { id: unit.id } }"
      v-if="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.UPDATE,
          subject: constants.PERMISSIONS_MODEL.UNITS
        }) && editUnit
      "
    >
      <span class="menu-icon-outer"><Edit class="drop-down-icon edit" /></span
      ><span class="align-middle ml-51">{{ $t("UnitTrack.Edit") }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      class="unit-menu-item"
      @click="deleteUnit({ id: unit.id, name: unit.name })"
      v-if="
        checkAbility({
          action: constants.PERMISSIONS_ACTION.DELETE,
          subject: constants.PERMISSIONS_MODEL.UNITS
        }) && editUnit
      "
    >
      <span class="menu-icon-outer"
        ><Delete class="drop-down-icon delete" /></span
      ><span class="align-middle ml-51">{{ $t("UnitTrack.Delete") }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem, BLink } from "bootstrap-vue";
import Events from "@/assets/images/unit-icon/menu/event-menu.svg";
import Trips from "@/assets/images/unit-icon/menu/trip-menu.svg";
import Activity from "@/assets/images/unit-icon/menu/activity-menu.svg";
import Videos from "@/assets/images/unit-icon/menu/video-menu.svg";
import Allocation from "@/assets/images/unit-icon/menu/allocation-menu.svg";
import Edit from "@/assets/images/unit-icon/menu/edit-menu.svg";
import Delete from "@/assets/images/unit-icon/menu/delete-menu.svg";
import Services from "@/assets/images/unit-icon/services-menu-icon.svg";
import constants from "@/utils/constants";
import History from "@/assets/images/unit-icon/menu/history.svg";
import account from "@/store/account";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    Events,
    Trips,
    Activity,
    Videos,
    Allocation,
    Edit,
    Delete,
    BLink,
    History,
    Services
  },

  data() {
    return {
      isAllocatedUnit: false
    };
  },
  props: ["unit", "deleteUnit", "allocationUnit", "editUnit"],
  mounted() {
    this.isAllocatedUnitData();
  },
  computed: {},
  methods: {
    isAllocatedUnitData() {
      this.isAllocatedUnit =
        this.checkAbility({
          action: constants.PERMISSIONS_ACTION.UPDATE,
          subject: constants.PERMISSIONS_MODEL.UNITS
        }) && localStorage.getItem("USER_SUB_ACCOUNTS_RESTRICTED") == "true"
          ? false
          : true;
    }
  }
};
</script>

<style lang="scss">
.unit-drop {
  .dropdown-item {
    text-align: left;
  }
}
.dropdown-menu-right.dropdown-menu {
  min-width: 170px;
}
.ml-51 {
  margin-left: 10px;
}
.drop-down-icon {
  path {
    fill: var(--primary);
  }
}
.drop-down-icon.edit {
  path {
    fill: none;
    stroke: var(--primary);
  }
}
.drop-down-icon.trips {
  circle {
    fill: var(--primary);
  }
  .white-fill {
    fill: var(--white);
  }
}
.drop-down-icon.delete {
  path {
    fill: none;
    stroke: var(--danger);
  }
}
.align-middle.ml-51 {
  color: var(--primary);
}
.menu-icon-outer {
  display: flex;
  width: 28px;
  justify-content: center;
  align-items: center;
}
.unit-menu-item {
  .dropdown-item {
    padding: 0.45rem 1.28rem;
    display: flex;
  }
}
.custom-unit-dropdown >>> .dropdown-menu {
  transform: none !important;
}
</style>
