import L from "leaflet";
import "./requestanimationframe.js";

const MAX_SPEED = 8;
const MIN_SPEED = 3;
const DEFAULT = 0; // Auto;
const MAX_TRIP_DISTANCE = 500;
const MAX_ZOOM_LEVEL = 19;

export var Clock = L.Class.extend({
  options: {
    speed: DEFAULT,
    maxSpeed: 65,
    _skipBlank: true
  },

  initialize: function (trackController, callback, map, options) {
    L.setOptions(this, options);
    this.map = map;
    this._trackController = trackController;
    this._callback = callback || function () {};
    this._curTime = this._trackController.getMinTime();
    this._lastFpsUpdateTime = 0;
    this._update();
  },
  _update() {
    this._endTime = this._trackController.getMaxTime();
    this._speed = this.getSpeed();
    this._drawnPoints = 0;
    this._lastDrawnPoints = 0;
    this._blankCount = 0;
    this._maxSpeed = this.options.maxSpeed;
    this._skipBlank = this.options._skipBlank;
  },

  caculateFps: function (now) {
    var fps = 1000 / (now - this._lastFpsUpdateTime);
    return fps;
  },

  caculatefpsTime: function (now) {
    var time = (now - this._lastFpsUpdateTime) / 1000;
    if (this._lastFpsUpdateTime === 0) {
      time = 0;
    }
    this._lastFpsUpdateTime = now;
    return time;
  },

  _tick: function () {
    const now = +new Date();
    let fpstime = this.caculatefpsTime(now);
    let isPause = false;
    if (this._skipBlank && this._blankCount > 100) {
      this._blankCount = 0;
      this._curTime = this._trackController.getNextTime(this._curTime);
    }
    var stepTime = fpstime * Math.pow(2, this._speed - 1);

    this._curTime += stepTime;
    if (this._curTime >= this._endTime) {
      this._curTime = this._endTime;
      isPause = true;
    }
    // const tps = this._trackController.getTrackPointsByTime(this._curTime);
    const drawnPoints = this._trackController.drawTracksByTime(this._curTime);
    this._lastDrawnPoints = drawnPoints - this._drawnPoints;
    this._drawnPoints = drawnPoints;
    if (this._lastDrawnPoints == 0) {
      this._blankCount = this._blankCount + 1;
    } else {
      this._blankCount = 0;
    }
    this._callback(this._curTime);
    if (!isPause) {
      this._intervalID = window.requestAnimationFrame(this._tick.bind(this));
    } else {
      this.stop();
    }
  },
  start: function () {
    if (this._intervalID) return;
    this._intervalID = window.requestAnimationFrame(this._tick.bind(this));
  },

  stop: function () {
    if (!this._intervalID) return;
    window.cancelAnimationFrame(this._intervalID);
    this._intervalID = null;
    this._lastFpsUpdateTime = 0;
    this._lastDrawnPoints = 0;
    this._drawnPoints = 0;
  },

  rePlaying: function () {
    this.stop();
    this._curTime = this._trackController.getMinTime();
    this.start();
  },

  slowSpeed: function () {
    this._speed <= 1 ? this._speed : (this._speed -= 1);
    if (this._intervalID) {
      this.stop();
      this.start();
    }
  },
  updateSpeed(speed) {
    speed = parseInt(speed);
    this._speed = speed > this._maxSpeed ? this._speed : speed;
    if (this._intervalID) {
      this.stop();
      this.start();
    }
  },
  quickSpeed: function () {
    this._speed >= this._maxSpeed ? this._speed : (this._speed += 1);
    if (this._intervalID) {
      this.stop();
      this.start();
    }
  },

  getSpeed: function () {
    if (this.options.speed == 0) {
      return this.calculatePlaybackSpeed();
    }
    return this.options.speed;
  },

  getCurTime: function () {
    return this._curTime;
  },

  getStartTime: function () {
    return this._trackController.getMinTime();
  },

  getEndTime: function () {
    return this._trackController.getMaxTime();
  },

  isPlaying: function () {
    return this._intervalID ? 1 : 0;
  },

  setCursor: function (time) {
    this._curTime = time;
    // this._tick();
    this._trackController.drawTracksByTime(this._curTime);
    this._callback(this._curTime);
  },

  setSpeed: function (speed) {
    this.options.speed = speed;
    this._speed = this.getSpeed();
    if (this._intervalID) {
      this.stop();
      this.start();
    }
  },
  calculatePlaybackSpeed: function () {
    const distance = this._trackController.getMaxDistance();
    const zoomLevel = this.map.getZoom();
    const normalizedDistance = distance / MAX_TRIP_DISTANCE; // Assuming MAX_TRIP_DURATION is a predefined maximum duration
    const normalizedZoom = zoomLevel / MAX_ZOOM_LEVEL; // Assuming MAX_ZOOM_LEVEL is a predefined maximum zoom level
    let baseSpeed =
      MIN_SPEED + (MAX_SPEED - MIN_SPEED) * (1 - normalizedDistance); //* (1 - normalizedZoom);
    const adjustedSpeed = Math.min(Math.max(baseSpeed, MIN_SPEED), MAX_SPEED);
    return adjustedSpeed;
  }
});

export var clock = function (trackController, callback, options) {
  return new Clock(trackController, callback, options);
};
