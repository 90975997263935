<template>
  <div
    :class="{
      'trip-details': true,
      'outer-info': true,
      'playback-only': isOnlyPlayBack,
      'payer-added-history': $route.name == 'unit-trips',
      'sidebar-close': !sidebarToggle,
      'sidebar-open': !!sidebarToggle
    }"
  >
    <button
      type="button"
      aria-label="Close"
      @click="clearUnitTripInfo"
      class="close"
    >
      &times;
    </button>
    <div class="unit-info-main">
      <UnitPlayBack
        v-if="unitTripInfo"
        :UnitInfo="unitTripInfo"
        :isOnlyPlayBack="isOnlyPlayBack"
        :isLiveSlide="isLiveSlide"
        :playback="playback"
        :startPlayback="startPlayback"
      ></UnitPlayBack>
    </div>
    <div
      class="unit-info-card new-wall-comp"
      :class="{
        'trip-card': $route.name == 'unit-trips'
      }"
      v-if="!isOnlyPlayBack"
    >
      <b-card no-body class="cust-card-unit">
        <div class="card-data-ev">
          <b-avatar
            variant="light-primary"
            pills
            size="45"
            :src="unitTripInfo.unit.icon"
          />
          <div class="unit-name-ev-block">
            <div class="unit-name-ev-text">{{ unitTripInfo.unit.name }}</div>
            <div class="allow-text">
              {{
                unitTripInfo.unit.allotted_account &&
                unitTripInfo.unit.allotted_account.name
              }}
            </div>
          </div>
        </div>
      </b-card>
      <div
        class="trip-sm-card"
        v-if="isMileageValid(unitTripInfo.mileage) && unitTripInfo.end.time"
      >
        <div class="event-card-fe" :title="$t('TripDetails.TripMileage')">
          <div class="ev-d-flex">
            <img
              :src="getIcon('/app/images/card-icon/mileage-meter-icon.svg')"
            />
            <div class="event-info">{{ unitTripInfo.mileage }}</div>
          </div>
        </div>
      </div>
      <div
        class="trip-sm-card"
        v-if="unitTripInfo.fuel && unitTripInfo.end.time"
      >
        <div class="event-card-fe" :title="$t('TripDetails.TripFuelConsumed')">
          <div class="ev-d-flex">
            <img :src="getIcon('/app/images/card-icon/pump.svg')" />
            <div class="event-info">{{ unitTripInfo.fuel }}</div>
          </div>
        </div>
      </div>
      <div
        class="trip-sm-card"
        v-if="unitTripInfo.duration && unitTripInfo.end.time"
      >
        <div class="event-card-fe" :title="$t('TripDetails.TripDuration')">
          <div class="ev-d-flex">
            <img :src="getIcon('/app/images/card-icon/car.svg ')" />
            <div class="event-info">
              {{ unitTripInfo.duration }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="trip-sm-card"
        v-if="unitTripInfo.speed && unitTripInfo.end.time"
      >
        <div class="event-card-fe" :title="$t('TripDetails.TripAvgSpeed')">
          <div class="ev-d-flex">
            <img :src="getIcon('/app/images/card-icon/avg-meter.svg')" />
            <div class="event-info">{{ unitTripInfo.speed }}</div>
          </div>
        </div>
      </div>
      <div
        class="trip-sm-card"
        v-if="false && unitTripInfo.max_speed && unitTripInfo.end.time"
      >
        <div class="event-card-fe" :title="$t('TripDetails.TripMaxSpeed')">
          <div class="ev-d-flex">
            <img :src="getIcon('/app/images/card-icon/avg-meter.svg')" />
            <div class="event-info">{{ unitTripInfo.max_speed }}</div>
          </div>
        </div>
      </div>
      <div
        class="trip-sm-card"
        v-if="unitTripInfo.distance && unitTripInfo.end.time"
      >
        <div class="event-card-fe" :title="$t('TripDetails.TripDistance')">
          <div class="ev-d-flex">
            <img :src="getIcon('/app/images/card-icon/location.svg')" />
            <div class="event-info">{{ unitTripInfo.distance }}</div>
          </div>
        </div>
      </div>
      <div
        class="trip-sm-card"
        v-if="false && unitTripInfo.refuel && unitTripInfo.end.time"
      >
        <div class="event-card-fe" :title="$t('TripDetails.TripFuelRefilled')">
          <div class="ev-d-flex">
            <img :src="getIcon('/app/images/card-icon/pump.svg')" />
            <div class="event-info">{{ unitTripInfo.refuel }}</div>
          </div>
        </div>
      </div>
      <div class="add-item">
        <div class="date-time">
          <b> {{ $t("UnitTrack.StartTime") }} : </b
          >{{
            DateTimeConvert(unitTripInfo.start.time, constants.DATE_TIME_FORMAT)
          }}

          <!-- <b-button
            variant="outline-primary"
            v-if="userModifiedTrackPosition"
            @click="recenterUnitTrack"
            class="btn-icon rounded-circle curserPointer"
            v-b-tooltip.hover.top="$t('unit.Recenter')"
          >
            <feather-icon icon="Navigation2Icon" />
          </b-button> -->
        </div>
        <div class="address-card text-primary">
          <AddressIcon class="address-icon" />
          <b-link
            :href="unitTripInfo.start.link"
            class="map-link"
            target="_blank"
          >
            {{
              unitTripInfo.start.address
                ? unitTripInfo.start.address
                : unitTripInfo.start.latitude +
                  " , " +
                  unitTripInfo.start.latitude
            }}
          </b-link>
        </div>
      </div>
      <div class="add-item">
        <div class="date-time">
          <b> {{ $t("UnitTrack.EndTime") }} : </b
          >{{
            DateTimeConvert(unitTripInfo.end.time, constants.DATE_TIME_FORMAT)
          }}
        </div>
        <div class="address-card text-primary">
          <AddressIcon class="address-icon" />
          <b-link
            :href="unitTripInfo.end.link"
            class="map-link"
            target="_blank"
          >
            {{
              unitTripInfo.end.address
                ? unitTripInfo.end.address
                : unitTripInfo.end.latitude && unitTripInfo.end.longitude
                ? unitTripInfo.end.latitude + " , " + unitTripInfo.end.longitude
                : ""
            }}
          </b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  VBTooltip,
  BTooltip,
  BLink,
  BButton
} from "bootstrap-vue";
import Badge from "@/layouts/components/Badge.vue";
import UnitPlayBack from "./UnitPlayBack";
import CallIcon from "@/assets/images/unit-icon/call.svg";
import AddressIcon from "@/assets/images/unit-icon/address-icon.svg";
import constants from "@/utils/constants";
export default {
  components: {
    BAvatar,
    BCard,
    Badge,
    VBTooltip,
    BLink,
    BTooltip,
    CallIcon,
    AddressIcon,
    BButton,
    UnitPlayBack
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      isHideMenu: false,
      isOnlyPlayBack: true
    };
  },

  props: [
    "unitTripInfo",
    "isLiveSlide",
    "clearUnitTripInfo",
    "playback",
    "sidebarToggle"
  ],
  mounted() {},
  computed: {},
  methods: {
    DateTimeConvert(date) {
      if (!date) {
        return "";
      }
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    getFormattedTime(time) {
      return this.updateReportTime(time);
    },
    getIcon(path) {
      return this.getCurrentPath() + path;
    },
    startPlayback(isPlayTrackStart) {
      if (isPlayTrackStart) {
        this.isOnlyPlayBack = true;
      } else {
        this.isOnlyPlayBack = !this.isOnlyPlayBack;
        if (!this.isOnlyPlayBack) {
          this.playback.pause();
        }
      }
    },
    isMileageValid(mileage) {
      let mileageString = mileage && mileage.split(" ")[0];
      if (mileageString) {
        const myNumber = Number(mileageString);
        if (
          !isNaN(myNumber) &&
          myNumber !== Infinity &&
          myNumber !== -Infinity
        ) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    recenterUnitTrack() {
      this.$emit("onRecenterTrackUnit");
    }
  }
};
</script>

<style lang="scss">
.new-wall-comp {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
}
.event-card-fe {
  position: relative;
  margin: 2px 5px;
  width: auto;
  padding: 17px 10px;
  border-radius: 6px;
  border: 1px solid #eceded;
  background: var(--white);
  box-sizing: border-box;
  display: inline-block;
  .event-info {
    font-size: 13px;
    font-weight: 600;
    padding-right: 10px;
    padding-left: 10px;
    white-space: nowrap;
  }
  .ev-d-flex {
    display: flex;
    align-items: center;
  }
}

.trip-details {
  .add-item {
    width: 100%;
    margin-top: 10px;
    .date-time {
      margin-bottom: 8px;
      margin-bottom: 8px;
    }
  }
  .address-card {
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
    border: 1px solid var(--gray8);
    width: 100%;
    display: flex;
    align-items: center;
    .map-link {
      max-width: calc(100% - 32px);
    }
  }

  .outer-span {
    position: relative;
    .info-icon-overlay {
      position: absolute;
      bottom: 15px;
      right: 5px;
      height: 20px;
      // background-color: var(--white);
      cursor: pointer;
    }
  }

  .outer-span,
  .inner-span {
    display: inline;
    position: relative;
    .event-card {
      min-height: 73px;
      padding-bottom: 25px;
      margin: 0;
    }
    .event-info {
      color: var(--darkgray);
    }
  }
  .new-wall-comp {
    .cust-card-unit {
      margin-right: 15px;
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      flex-direction: column;
    }
  }

  .unit-info-card {
    background-color: var(--white);
    padding: 15px;
    border-radius: 0px 0px 4px 4px;
    max-height: 285px;
    overflow-y: auto;
    box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
    display: flex;
    flex-wrap: wrap;
    .b-avatar {
      .b-avatar-img {
        padding: 4px;
        img {
          object-fit: contain;
        }
      }
    }
    .cust-card-unit {
      display: inline-flex;
      padding: 15px;
      margin-right: 10px;
      min-width: 250px;
      max-width: 250px;
      min-height: 53px;

      .unit-name-ev-block {
        padding-left: 15px;
        width: 100%;
        .media.event-Tag {
          padding-top: 5px;
        }
        .unit-name-ev-text {
          font-size: 16px;
          font-weight: 500;
          color: var(--unit-bg-dark);
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          max-width: 87%;
          white-space: nowrap;
        }
        .unit-light-ev-text {
          font-size: 14px;
          min-width: 70%;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          max-width: 255px;
          white-space: nowrap;
        }
      }
      .card-data-ev {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        justify-content: space-between;
      }
    }
    .allocate-info {
      display: flex;
      justify-content: space-between;
      .allow-text {
        font-size: 14px;
        font-weight: 500;
      }
      .shift-txt {
        font-weight: normal;
      }
    }
    .shift-time {
      color: var(--unit-bg-dark);
      display: flex;
      align-items: center;
    }
    .address-block {
      display: flex;
      .address-txt {
        font-size: 14px;
        font-weight: 500;
        padding-left: 15px;
        width: calc(100% - 15px);
        max-height: 85px;
        overflow-y: auto;
      }
    }
    .call-icon,
    .address-icon {
      margin-right: 10px;
      path {
        fill: var(--primary);
      }
    }
  }
  .outer-info .close {
    position: absolute;
    right: -10px;
    top: -10px;
    height: 30px;
    width: 30px;
    background-color: var(--white) !important;
    box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
    z-index: 999999;
    padding: 0;
    border-radius: 6px;
    opacity: 1;
  }
}
.dark-layout {
  .trip-details {
    .unit-info-card {
      background-color: var(--unit-bg-dark);
      .cust-card-unit {
        .unit-name-ev-block {
          .unit-name-ev-text {
            color: var(--light);
          }
        }
      }
      .shift-time {
        color: var(--light);
      }
    }
  }
  .event-card-fe {
    background-color: var(--dark46);
  }
  .outer-info .close {
    background-color: var(--dark46) !important;
    color: var(--white);
  }
}
@media screen and (max-width: 767px) {
  // .trip-card {
  //   max-height: 80px !important;
  // }
  .trip-sm-card {
    width: 50%;
    margin-bottom: 8px;
    .event-card-fe {
      width: 100%;
      box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    }
  }
  .trip-sm-card:nth-child(odd) {
    padding-left: 5px;
  }
  .trip-sm-card:nth-child(even) {
    padding-right: 5px;
  }
  .trip-details {
    .unit-info-card {
      max-height: 100%;
      .cust-card-unit,
      .cust-card-unit.last-cust-card-unit {
        min-width: 100%;
        max-width: 100%;
        min-height: auto;
        margin-right: 0px;
      }
      .address-block {
        .address-txt {
          max-height: none;
        }
      }
    }
  }
}
.no-wp-txt {
  white-space: nowrap;
  font-size: 11px;
}
.unit-info-main {
  border-radius: 8px;
  background-color: var(--white);
  padding: 15px;
  min-height: 122px;
  box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
  display: flex;
  flex-wrap: wrap;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  .v-select ul {
    margin-top: -80px !important;
  }
}
</style>
