<template>
  <div>
    <div class="ml-1 mb-1">
      <!-- dfddf {{ unitsData }} -->
      <b-form-checkbox
        v-if="totalUnits"
        style="min-width: 10px"
        :title="$t('Unit.SelectAll')"
        v-model="isClearAll"
        @change="clearAll"
      ></b-form-checkbox>
    </div>
    <div class="unit-list-scroll" ref="scrollContainer">
      <div class="table-responsive" v-if="showLoading">
        <b-skeleton-table
          :rows="6"
          :columns="2"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>

      <div v-else>
        <virtual-list
          style="overflow-y: auto"
          :style="
            !showLoading &&
            totalUnits === 0 &&
            (!filter || !filter.value) &&
            checkAbility({
              action: constants.PERMISSIONS_ACTION.ADD,
              subject: constants.PERMISSIONS_MODEL.UNITS
            })
              ? ''
              : { height: 'calc(100vh - 410px)' }
          "
          :data-key="'id'"
          :data-sources="unitsData"
          :estimate-size="10"
          :dataComponent="{}"
          v-on:tobottom="handleScroll"
          ><template #item="{ item: unit }">
            <!-- Your HTML template for each item goes here -->
            <div
              class="align-items-center b-card unit-ls-data"
              :class="
                selectedCheckbox.includes(unit.id) ? 'border-primary' : ''
              "
            >
              <!-- Add more HTML to display other properties of the item -->

              <b-media no-body class="media-card d-flex">
                <b-form-checkbox
                  :value="unit.id"
                  v-model="selectedCheckbox"
                  @change="onCheckClick(unit, $event, unitsData, false)"
                  class="form-control-merge cursor-pointer"
                >
                </b-form-checkbox>
                <b-media-aside class="mr-75 marginLeftImg unit-url">
                  <b-avatar square size="45" :src="unit.icon" />
                </b-media-aside>
                <b-media-body
                  class="my-auto mb-body cursor-pointer"
                  @click="clickOnUnitName(unit, true)"
                >
                  <h6 class="mb-0 marginLeftImg text-col-unit">
                    {{ unit.name }}
                  </h6>
                  <div class="unit-account-name">
                    {{ unit.operator.name }}
                  </div>
                </b-media-body>
                <b-img
                  v-if="unit && unit.battery && unit.battery.icon"
                  :src="unit.battery.icon"
                  class="battery-img-icon curserPointer"
                  v-b-tooltip.html.top
                  :title="`<b class='no-wp-txt'>${$t(
                    'unit.batteryLevel'
                  )}:</b><span class='no-wp-txt'>  ${
                    unit.battery.level
                  }  </span>`"
                />
                <b-img
                  :src="unit.connection.icon"
                  class="signal-img-icon curserPointer"
                  v-b-tooltip.html.top
                  :title="`<b class='no-wp-txt'>${$t(
                    'UnitTrack.ReportedAt'
                  )}:</b><span class='no-wp-txt'>  ${getFormattedTime(
                    unit.message_time
                  )}  </span>`"
                />
                <UnitActionMenu
                  :unit="unit"
                  :deleteUnit="deleteUnit"
                  :allocationUnit="allocationUnit"
                  :editUnit="unit.is_unit_editable"
                />
              </b-media>
              <div
                class="d-flex justify-content-end unit-info-icon"
                v-if="unit.archived == true"
              >
                <span
                  class="mb-0 curserPointer"
                  v-b-tooltip.html.top
                  :title="`
        <b class='no-wp-txt'>${$t('unit.UnitAllocationMessage')}</b>
         `"
                >
                  <feather-icon
                    class="text-primary"
                    icon="InfoIcon"
                    size="15"
                  />
                </span>
              </div>
            </div>
          </template>
        </virtual-list>
        <div
          v-if="
            !showLoading &&
            totalUnits === 0 &&
            (!filter || !filter.value) &&
            checkAbility({
              action: constants.PERMISSIONS_ACTION.ADD,
              subject: constants.PERMISSIONS_MODEL.UNITS
            })
          "
        >
          <div>
            <h5>
              {{ $t("unit.EmptyUnitCreateMessage") }}
            </h5>
          </div>

          <div style="text-align: center">
            <b-button
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.UNITS
                })
              "
              variant="primary"
              :to="{
                name: 'unit-creation',
                query: { allocate_to: selectedAccount }
              }"
            >
              <feather-icon icon="PlusIcon" size="12" class="add-icon-s" />
              <span class="d-none d-sm-inline">{{
                $t("device.CreateUnit")
              }}</span>
            </b-button>
          </div>
        </div>
      </div>
      <AreYouSureModal
        :data="deleteData"
        :onClose="onClose"
        :removedUser="removedUser"
      />
      <AllocationPopUp
        v-if="isAllocationEnable"
        :allocationData="allocationData"
        :isDevice="false"
        :isSelectedTab="isSelectedTab"
        :onClose="onClose"
        :changeInput="changeInput"
        :currentUnit="currentUnit"
        :refreshed="refreshed"
        :getAccountDetail="getAccountDetail"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BMediaBody,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BInputGroup,
  BFormGroup,
  BImg,
  BFormCheckbox,
  BMediaAside,
  VBTooltip,
  BCardBody,
  BSkeletonTable,
  BButton
} from "bootstrap-vue";

import UnitService from "@/libs/api/unit-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import GsmSignal from "@/views/sensor-views/gsmSignal.vue";
import ImageFallBack from "vue-fall-back-image-directive";
import UnitActionMenu from "@/views/Unit/UnitTracking/shared/UnitActionMenu.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import AllocationPopUp from "@/views/Unit/AllocationPopUp.vue";
import constants from "@/utils/constants";
import SocketIOService from "@/libs/socket/sockets";
import store from "@/store";
import VirtualList from "vue-virtual-scroll-list";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BMediaBody,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    GsmSignal,
    BImg,

    BMediaAside,
    ImageFallBack,
    VBTooltip,
    BCardBody,
    AreYouSureModal,
    AllocationPopUp,
    UnitActionMenu,
    BSkeletonTable,
    BButton,
    BFormCheckbox,
    VirtualList
  },
  data() {
    return {
      selected: "10",
      option: ["10", "20", "30"],
      page: 1,
      page_size: 100,
      totalUnits: 0,
      showFilter: false,
      isAllocationEnable: false,
      selectedCheckbox: [],
      currentPage: 1,
      items: [],
      unitsData: [],
      unitsData1: [],
      deleteData: null,
      isClearAll: false,
      filter: {},
      unitFilter: {},
      url: process.env.VUE_APP_ICON_URL,
      showLoading: "false",
      allocationData: null,
      currentUnit: null,
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    };
  },
  props: [
    "updateCheckBoxVal",
    "searchFiled",
    "unitStatusFiltering",
    "selectedAccount",
    "selectedMenu",
    "sendSelectedUnit",
    "lastMessageTimeUpdate",
    "playback"
  ],
  setup() {},
  directives: {
    "b-tooltip": VBTooltip
  },
  computed: {},
  watch: {
    selectedCheckbox(val) {
      setTimeout(() => {
        if (
          this.selectedCheckbox &&
          this.selectedCheckbox.length == this.unitsData.length
        ) {
          this.isClearAll = true;
        } else {
          this.isClearAll = false;
        }
      }, 100);
    },
    lastMessageTimeUpdate: {
      handler(val) {
        this.unitsData.forEach((unit) => {
          if (unit.id === val.id) {
            if (val.connection) {
              unit.connection = val.connection;
            }
            if (val.message_time) {
              unit.message_time = val.message_time;
            }
            if (val.battery) {
              unit.battery = val.battery;
            }
          }
        });
      },
      deep: true
    },

    searchFiled(newVal, oldVal) {
      if (
        ((!oldVal || !oldVal.value) && newVal && !newVal.value) ||
        (oldVal && newVal && oldVal.value == newVal.value)
      ) {
        return;
      } else {
        this.filter = newVal;
        this.page = 1;
        this.page_size = 100;
        this.getAllUnitList(false);
      }
    },
    unitStatusFiltering(newVal, oldVal) {
      if (
        ((!oldVal ?? !oldVal.length) && newVal && !newVal.length) ??
        (oldVal && newVal && oldVal.length == newVal.length)
      ) {
        return;
      } else {
        this.unitFilter = {
          field: "status_units",
          operator: "IN",
          value: newVal
        };
        this.page = 1;
        this.page_size = 100;
        this.getAllUnitList(false);
      }
    },

    selectedAccount(val) {
      this.selectedCheckbox = [];
      this.page = 1;
      this.page_size = 100;
      this.getAllUnitList(false);
    },
    selectedMenu(val, olVal) {
      if (val && val === "units") {
      } else if (val && val === "events" && olVal && olVal === "units") {
        this.playback.clearAllMarkers();
      } else {
        this.playback.clearAllMarkers();
      }

      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
      }
    },
    $route(to, from) {
      if (to.name !== "unit-map") {
        this.selectedCheckbox = [];
        this.playback.clearMap();
      }
      if (from.name === "unit-events" && to.name === "unit-map") {
        this.map.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            layer.remove();
          }
        });
      }
    }
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (page) {
        this.currentPage = parseInt(page);
      }
      if (perPage) {
        this.perPage = parseInt(perPage);
      }
    });
  },
  methods: {
    handleScroll() {
      if (this.totalUnits > this.page * this.page_size) {
        this.page++;
        this.getAllUnitList(true);
      }
    },
    clickOnUnitName(unit, isShowInfo) {
      if (this.selectedCheckbox.includes(unit.id)) {
      } else {
        this.selectedCheckbox.push(unit.id);
      }
      this.onCheckClick(unit, this.selectedCheckbox, this.unitsData);
      if (isShowInfo && this.selectedCheckbox.includes(unit.id)) {
        this.playback.getUnitMarker(unit.id);
        this.playback.events.fire("pointClick", {
          message: { id: unit.id },
          screen: "units"
        });
        this.$router
          .push({
            name: this.$route.name,
            query: { ...this.$route.query, id: unit.id }
          })
          .catch((err) => {});
      }
      this.playback.removeTrack(unit);
      // this.onCheckClick(unit, this.selectedCheckbox, this.unitsData);
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove");
    },
    clearAll() {
      if (!this.isClearAll) {
        this.selectedCheckbox.forEach((id) => {
          this.subscribeUnitData({ id }, false);
          // this.playback.removeTrack(unit);

          this.playback.removeUnitMarker(id);
        });
        this.selectedCheckbox = [];
        this.playback.moveToEnd();
        this.playback.recenterMap();
      } else {
        this.unitsData.forEach((unit) => {
          if (!unit.archived) {
            this.clickOnUnitName(unit, false);
          }
          if (!this.selectedCheckbox.includes(unit.id)) {
            this.selectedCheckbox.push(unit.id);
          }
        });
      }
    },
    async getAllUnitList(isScroll) {
      try {
        if (!isScroll) {
          this.showLoading = true;
          this.playback.clearAllMarkers();
        }
        if (this.$route.name !== "unit-map") {
          return;
        }
        if (!this.selectedAccount) {
          return;
        }
        let filters =
          Object.keys(this.filter).length > 0 && this.filter.value
            ? [this.filter]
            : [];

        let unitFilters =
          Object.keys(this.unitFilter).length > 0 &&
          this.unitFilter.value.length
            ? [this.unitFilter]
            : [];
        const allFilters =
          filters.length || unitFilters.length
            ? {
                filters: [...filters, ...unitFilters]
              }
            : {};
        let requestData = {
          page: this.page,
          page_size: this.page_size,
          account_id: this.selectedAccount || this.$route.query.account_id,
          id: this.$route.query.source ? this.$route.query.id : undefined,
          ...allFilters
        };

        let response = await new UnitService().getUnitListV2(requestData);
        this.showLoading = false;
        if (response && response.data) {
          const unitItem = response.data.list || [];

          this.unitsData = isScroll
            ? [...this.unitsData, ...unitItem]
            : unitItem;
          if (unitItem && unitItem.length) {
            this.$router
              .push({
                name: this.$route.name,
                query: {
                  ...this.$route.query,
                  id: unitItem[unitItem.length - 1].id
                }
              })
              .catch((err) => {});
          }
          // if (!isScroll) {
          if (this.isClearAll) {
            unitItem.forEach((unit) => {
              if (!unit.archived) {
                this.clickOnUnitName(
                  unit,
                  this.$route.query.source ? true : false
                );
              }
              if (!this.selectedCheckbox.includes(unit.id)) {
                this.selectedCheckbox.push(unit.id);
              }
            });
          }
          // }

          this.totalUnits = response.data.pagination.total_records;

          if (this.totalUnits === 0) {
            store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
          } else {
            store.commit("mapLoader/SET_IS_POPUP_SHOW", false);
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.showLoading = false;
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    getFormattedTime(time) {
      return this.updateReportTime(time);
    },
    onCheckClick(unit, flag, list) {
      setTimeout(() => {
        if (flag.includes(unit.id)) {
          const unitInfo = {
            id: unit.id,
            unit_id: unit.id,
            name: unit.name,
            icon: unit.icon,
            screen: "units",
            isLoad: true,
            points: [
              {
                id: unit.id,
                latitude: unit.position.latitude,
                longitude: unit.position.longitude,
                angle: unit.position.angle,
                speed: 0,
                distance: 0,
                date: new Date()
              }
            ]
          };
          // this.playback.addTrack(unitInfo, unitInfo.points);
          this.playback.addUnitMarker({
            lat: unit.position.latitude,
            lng: unit.position.longitude,
            angle: unit.position.angle,
            icon: unit.icon,
            element: [unitInfo.points],
            id: unit.id
          });
          this.playback.moveToEnd();
          this.playback.recenterMap();
          this.subscribeUnitData(unit, true);

          // this.$router
          //   .push({
          //     name: this.$route.name,
          //     query: { ...this.$route.query, id: unit.id }
          //   })
          //   .catch((err) => {});
          setTimeout(() => {
            this.playback.moveToEnd();
            this.playback.recenterMap();
          }, 100);
        } else {
          this.subscribeUnitData(unit, false);
          // this.playback.removeTrack(unit);
          this.playback.removeUnitMarker(unit.id);
          this.playback.moveToEnd();
          this.playback.recenterMap();
        }
      }, 100);
    },
    async removedUser(props) {
      this.deleteData = { name: "", id: "" };
      if (props.id) {
        try {
          this.onClose();
          let response = await new UnitService().deleteUnit({
            id: props.id
          });

          if (response && response.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("unit.DeletedSuccessfully"),
                icon: "EditIcon",
                variant: "success"
              }
            });
            if (this.unitsData.map((e) => e.id).includes(props.id)) {
              this.unitsData = this.unitsData.filter((e) => e.id !== props.id);
            }
          } else if (response && response.error && response.error.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "EditIcon",
                variant: "error"
              }
            });
          }
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    },
    isSelectedTab() {
      this.$bvModal.hide("is-unit-allocated");
    },
    async changeInput(allocationData) {
      this.allocationData = allocationData;
    },
    deleteUnit(props) {
      this.deleteData = { name: props.name, id: props.id };
      this.$bvModal.show("modal-sm-remove");
    },
    getAccountDetail(data) {
      this.isConsumerAccount = data.type == "CONSUMER";
    },
    allocationUnit(props) {
      this.isAllocationEnable = true;
      setTimeout(() => {
        this.$bvModal.show("unit-allocate");
      }, 2000);

      this.allocationData = {
        ...props,
        start_date: props.allotted_account.start_date,
        end_date: props.allotted_account.end_date,
        allocate_to_id: props.allotted_account.id,
        unit_driver_id: props.operator.id
      };
      this.currentUnit = Object.assign(props, {});
      this.currentUnit.allocate_to_id = this.currentUnit.allotted_account.id;
      this.currentUnit.unit_driver_id = this.currentUnit.operator.id;
    },
    refreshed() {
      this.getAllUnitList();
    },
    async subscribeUnitData(unit, subscribe) {
      if (!this.socket) {
        this.socket = new SocketIOService();
        await this.socket.setupSocketConnection();
      }
      if (subscribe && !unit.subscribe) {
        const posItem = this.unitsData.find((item) => item.id == unit.id);
        unit.subscribed = true;

        this.socket.subscribeEventWithFunction(`UM_${unit.id}`, (data) => {
          if (!this.selectedCheckbox.includes(unit.id)) {
            this.socket.unsubscribeEventWithFunction(`UM_${unit.id}`);
            return;
          }
          if (this.$route.name !== "unit-map") {
            return;
          }
          if (posItem) {
            let lastPositionData = data.device.data.find(
              (msg) => msg.type == "GPS"
            );
            if (
              lastPositionData &&
              lastPositionData.data &&
              lastPositionData.data.latitude &&
              lastPositionData.data.longitude
            ) {
              for (let index = 0; index < this.unitsData.length; index++) {
                const element = this.unitsData[index];
                if (posItem.id === element.id) {
                  this.unitsData[index].position.longitude =
                    lastPositionData.data.longitude;
                  this.unitsData[index].position.latitude =
                    lastPositionData.data.latitude;
                  this.unitsData[index].position.angle =
                    lastPositionData.data.angle;
                  if (data.connection) {
                    this.unitsData[index].connection = data.connection;
                  }
                  if (data.message_time) {
                    this.unitsData[index].message_time = data.message_time;
                  }
                  if (data.battery) {
                    this.unitsData[index].battery = data.battery;
                  }

                  lastPositionData.data.distance =
                    lastPositionData.data.distance / 1000;
                  const date = new Date(data.message_time);
                  const isTrack = this.playback.getUnitMarker(unit.id);
                  let lastDistance = 0;
                  // if (isTrack) {
                  //   lastDistance = isTrack.getTrackDistance();
                  // }
                  // const track = {
                  //   unit_id: unit.id,
                  //   id: unit.id,
                  //   screen: "unit",
                  //   icon: unit.icon,
                  //   is_live: true
                  // };
                  // track.points = {
                  //   id: data.device.message_id,
                  //   latitude: lastPositionData.data.latitude,
                  //   longitude: lastPositionData.data.longitude,
                  //   angle: lastPositionData.data.angle,
                  //   speed: lastPositionData.data.gps_speed,
                  //   distance: lastDistance + lastPositionData.data.distance,
                  //   date: date.valueOf()
                  // };

                  if (!isTrack) {
                    this.playback.addUnitMarker({
                      id: unit.id,
                      lat: lastPositionData.data.latitude,
                      lng: lastPositionData.data.longitude,
                      angle: lastPositionData.data.angle,
                      icon: unit.icon
                    });
                  } else {
                    this.playback.updateUnitMarker({
                      id: unit.id,
                      lat: lastPositionData.data.latitude,
                      lng: lastPositionData.data.longitude,
                      angle: lastPositionData.data.angle,
                      icon: unit.icon,
                      updateLocation:
                        this.selectedCheckbox &&
                        this.selectedCheckbox.length === 1
                          ? true
                          : false
                    });
                  }
                  this.playback.events.fire("onLive", {
                    ...data,
                    unit_id: posItem.id
                  });
                }
              }
            }
          }
        });
      } else if (unit.subscribed) {
        unit.subscribed = false;
        this.socket.unsubscribeEventWithFunction(`UM_${unit.id}`);
      }
    }
  }
};
</script>
<style lang="scss">
@import "./../index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
</style>
<style lang="scss" scoped>
.unit-ls-data {
  position: relative;
  box-shadow: none;
  border: 1px solid var(--gray-605);
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 7px;
  padding: 15px 12px;
  position: relative;
  .media-aside.unit-url {
    .b-avatar {
      .b-avatar-img {
        padding: 4px;
        img {
          object-fit: contain;
        }
      }
    }
  }
  .b-avatar.badge-secondary {
    background-color: var(--gray-605);
    border-radius: 50% !important;
    margin-left: 6px;
  }
  .no-gsm-text {
    padding-top: 0px;
    margin-left: 10px;
    margin-right: 4px;
    .signal-icon.mr-75 {
      margin-right: 0px !important;
    }
    .gsm-text {
      display: none;
    }
  }
  .media-card {
    width: 100%;
    padding-top: 0px;
  }
  .unit-account-name {
    font-size: 10px;
    margin-top: 4px;
  }
}
.dark-layout {
  .unit-ls-data {
    .b-avatar.badge-secondary {
      background-color: var(--light);
    }
  }
}
.unit-list-scroll {
  max-height: calc(100vh - 410px);
  min-height: calc(100vh - 410px);
  overflow-y: auto;
  margin-top: 15px;
}
@media screen and (max-width: 667px) {
  .unit-list-scroll {
    max-height: calc(100vh - 330px);
    min-height: calc(100vh - 330px);
  }
}
.unit-info-icon {
  position: absolute;
  bottom: 3px;
  right: 8px;
}
.add-icon-s {
  vertical-align: middle !important;
  margin-right: 5px;
}
.battery-img-icon {
  margin-right: 15px;
}
</style>
