<template>
  <div class="service-list-scroll" ref="scrollContainer" @scroll="handleScroll">
    <div class="table-responsive" v-if="showLoading == 0">
      <b-skeleton-table
        :rows="6"
        :columns="2"
        :table-props="{ bordered: true, striped: true }"
      />
    </div>
    <div v-if="showLoading == 1" class="mt-1">
      <b-jumbotron :lead="$t('UnitService.NoReminderText')"></b-jumbotron>
    </div>
    <div v-if="showLoading == 2">
      <div
        class="service-card"
        v-for="(service, index) in serviceData"
        :key="index"
      >
        <div class="service-icons-outer">
          <div
            class="service-heading"
            @click="OnReminderView(service.service_id)"
          >
            {{ service.name }}
          </div>

          <div v-if="service.services" class="service-icons">
            <div
              v-for="(serviceInfo, index) in service.services.slice(0, 3)"
              :key="index"
            >
              <div
                :id="'circle-' + index"
                v-b-tooltip.hover.top="{
                  title: serviceInfo.title || '',
                  customClass: 'service-info'
                }"
              >
                <b-img
                  class="service-icon"
                  :src="serviceInfo.icon"
                  v-if="serviceInfo.icon"
                />
              </div>
            </div>
            <!-- Show more badge -->
            <b-badge
              variant="primary"
              pill
              id="popover-target-service"
              v-if="service.services.length > 3"
              class="show-more-badge"
            >
              + {{ service.services.length - 3 }}
            </b-badge>

            <b-popover
              target="popover-target-service"
              triggers="hover"
              custom-class="custom-popover"
              placement="left"
            >
              <div
                v-for="(serviceInfo, index) in service.services.slice(3)"
                :key="index"
              >
                <div
                  :id="'circle-' + index"
                  v-b-tooltip.hover.top="{
                    title: serviceInfo.title || '',
                    customClass: 'service-info'
                  }"
                >
                  <b-img
                    class="service-icon"
                    :src="serviceInfo.icon"
                    v-if="serviceInfo.icon"
                  />
                </div>
              </div>
            </b-popover>
          </div>
        </div>
        <div v-if="service.configuration && service.configuration.length">
          <div
            class="info-unit-service"
            v-for="(configuration, index) in service.configuration.filter(
              (e) => e.type === 'TIME'
            )"
            :key="index"
          >
            <div v-if="configuration.type === 'TIME'">
              <div class="d-flex justify-content-between">
                <div class="servicedue-date">
                  <div>
                    <feather-icon
                      icon="CalendarIcon"
                      size="16"
                      class="calender-icon"
                    />
                  </div>
                  <div class="service-date">
                    {{ configuration.value }}
                  </div>
                </div>
              </div>
              <div class="d-flex time">
                <div>
                  <feather-icon
                    icon="ClockIcon"
                    class="calender-icon"
                    size="16"
                  />
                </div>
                <div>
                  {{ configuration.time }}
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <feather-icon
                    icon="BellIcon"
                    class="calender-icon"
                    size="16"
                  />
                </div>
                <div class="due">
                  {{ configuration.due }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-icon">
          <span
            class="text-primary"
            v-b-tooltip.hover.top="{
              title: service.info_message || '',
              customClass: 'service-info'
            }"
          >
            <feather-icon icon="InfoIcon" id="info" size="18" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BJumbotron,
  BTooltip,
  VBTooltip,
  BImg,
  BSkeletonTable,
  BBadge,
  BPopover
} from "bootstrap-vue";
import constants from "@/utils/constants";
import MaintenanceService from "@/libs/api/maintenance-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BButton,
    BJumbotron,
    BTooltip,
    VBTooltip,
    BImg,
    BPopover,
    BSkeletonTable,
    BBadge
  },
  data() {
    return {
      showLoading: 0,
      page: 1,
      page_size: 10,
      totalServices: 0,
      clearPage: false,
      items: [],
      filter: "",
      list: [],
      serviceData: [],
      serviceIconData: [],
      url: process.env.VUE_APP_ICON_URL
    };
  },
  props: ["unitDateFilter", "selectedMenu"],
  watch: {
    unitDateFilter: {
      deep: true,
      handler(newOptions) {
        this.page = 1;
        this.page_size = 10;
        this.getAllReminders(false);
      }
    },
    selectedMenu(val) {
      if (val === "service") {
        this.page = 1;
        this.page_size = 10;
        this.getAllReminders();
      }
    }
  },
  directives: {
    "b-tooltip": VBTooltip
  },

  mounted() {
    this.getAllReminders(false);
  },
  methods: {
    OnReminderView(id) {
      this.$router.push({
        name: "maintenance-reminder-view",
        params: { id }
      });
    },
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollOffset =
        scrollContainer.scrollHeight - scrollContainer.scrollTop - 1;
      const containerHeight = scrollContainer.clientHeight;
      if (scrollOffset > containerHeight) {
        if (this.totalServices > this.page * this.page_size) {
          this.page++;
          this.getAllReminders(true);
        }
      }
    },

    async getAllReminders(isScroll) {
      try {
        store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
        if (
          !this.unitDateFilter ||
          !this.unitDateFilter.selectedUnit ||
          !this.unitDateFilter.account_id ||
          !this.unitDateFilter.start_time ||
          !this.unitDateFilter.end_time ||
          this.unitDateFilter.start_time === "Invalid date" ||
          this.unitDateFilter.end_time === "Invalid date"
        ) {
          store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
          return;
        }

        let filters;
        if (
          this.unitDateFilter &&
          this.unitDateFilter.start_time &&
          this.unitDateFilter.end_time &&
          this.unitDateFilter.start_time !== "Invalid date" &&
          this.unitDateFilter.end_time !== "Invalid date"
        ) {
          filters = [
            {
              field: "status",
              value: ["UPCOMING"],
              operator: "IN"
            },
            {
              field: "time",
              start_value: this.unitDateFilter.start_time,
              end_value: this.unitDateFilter.end_time,
              operator: "between"
            }
          ];
        }
        if (
          !this.unitDateFilter.selectedUnit ||
          !this.unitDateFilter.account_id
        ) {
          return;
        }
        if (!isScroll) {
          this.showLoading = 0;
        }

        let requestData = {
          unit_id: this.unitDateFilter.selectedUnit,
          account_id: this.unitDateFilter.account_id,
          filters,
          page: this.page,
          page_size: this.page_size,
          sort_order: "ASC"
        };
        if (this.unitDateFilter.selectedUnit) {
          requestData.filters.push({
            field: "unit_id",
            value: [this.unitDateFilter.selectedUnit],
            operator: "IN"
          });
          // requestData.filters.push({
          //   field: "unit_id",
          //   value: [this.unitDateFilter.selectedUnit],
          //   operator: "IN"
          // });
        }
        let response = await new MaintenanceService().getAllReminders(
          requestData
        );

        if (response && response.data && response.data.list) {
          this.showLoading = 2;
          const service = response.data.list || [];

          if (!isScroll) {
            this.serviceData = service;
          } else {
            this.serviceData.push(...service);
          }

          this.totalServices = response.data.pagination.total_records || 0;

          if (this.totalServices === 0) {
            this.showLoading = 1;
          }

          if (this.clearPage) {
            this.$router.replace({ query: {} });
          }
        } else if (response && response.error && response.error.message) {
          this.showLoading = 1;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = 1;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    DateTimeConvert(date, format) {
      return this.formattedDateTime(date, format);
    }
  }
};
</script>
<style lang="scss">
.popover.custom-popover {
  max-width: auto; /* Set your desired width */
  display: flex;

  .popover-body {
    width: auto;
    display: flex;
  }
}
</style>
<style lang="scss">
#circle {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}
.info-icon {
  margin-left: 0px;
}
.service-heading {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);
  cursor: pointer;
}
.service-card-active {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid var(--primary);
  border-radius: 6px;
}
.dark-layout {
  .service-card {
    border: 1px solid var(--darkgray);
  }
}
.service-card {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid var(--gray-605);
  border-radius: 6px;
}
.due {
  font-size: 14px;
}
.time {
  margin-top: 5px;
  margin-bottom: 5px;
}
.servicedue-date {
  width: 125px;
  display: flex;
  align-items: center;
}
.service-icons {
  width: 115px !important;
  display: flex;
  margin-top: 27px;
}
.dark-layout {
  .service-icon {
    border: 1px solid var(--darkgray);
  }
}

.service-icon {
  width: 23px;
  height: 23px;
  border: 1px solid var(--gray-605);
  border-radius: 50%;
  margin-right: 8px;
}
.service-info {
  max-width: 266px;
  text-align: right;
}
.calender-icon {
  margin-right: 5px;
}
.icon {
  margin-right: 8px;
  cursor: pointer;
}
#info {
  cursor: pointer;
}
.service-list-scroll {
  min-height: calc(100vh - 380px);
  max-height: calc(100vh - 380px);
  overflow-y: auto;
  padding-right: 4px;
}
.info-unit-service {
  min-width: 20%;
}
.show-more-badge {
  cursor: pointer;
  margin-top: auto;
}
@media screen and (max-width: 667px) {
  .service- list_-scroll {
    min-height: calc(100vh - 275px);
    max-height: calc(100vh - 275px);
    padding-right: 4px;
  }
}
</style>
