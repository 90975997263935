<template>
  <div>
    <div v-if="historyNotFound" class="mt-1">
      <b-jumbotron :lead="historyNotFound"> </b-jumbotron>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BMediaBody,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BInputGroup,
  BFormGroup,
  BImg,
  BFormCheckbox,
  BMediaAside,
  VBTooltip,
  BCardBody,
  BSkeletonTable,
  BJumbotron,
  BContainer
} from "bootstrap-vue";

import UnitService from "@/libs/api/unit-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ImageFallBack from "vue-fall-back-image-directive";
import StartPoint from "@/assets/images/unit-icon/start-point.svg";
import * as moment from "moment";
import constants from "@/utils/constants";
import * as Utils from "@/libs/utils";
import EndPoint from "@/assets/images/unit-icon/end-point.svg";
import SocketIOService from "@/libs/socket/sockets";
import store from "@/store";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BMediaBody,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BImg,
    BFormCheckbox,
    BMediaAside,
    ImageFallBack,
    VBTooltip,
    BCardBody,
    StartPoint,
    EndPoint,
    BSkeletonTable,
    BJumbotron,
    BContainer
  },
  data() {
    return {
      selected: "10",
      trips: [],
      showLoading: 1,
      totalHistory: 0,
      historyNotFound: "",
      unit: {},
      page_size: 10000,
      page: 1,
      timeOutHistory: 0
    };
  },

  props: ["unitDateFilter", "playback", "selectedMenu"],
  setup() {},
  directives: {
    "b-tooltip": VBTooltip
  },

  watch: {
    selectedMenu(val) {
      if (val == "unit-history") {
        this.getAllHistory(false);
      }
    },
    unitDateFilter: {
      deep: true,
      handler(newOptions) {
        this.playback.clearMap();
        this.playback.moveToEnd();
        this.playback.recenterMap();
        clearTimeout(this.timeOutHistory);
        this.timeOutHistory = setTimeout(() => {
          this.getAllHistory(false);
        }, 200);
        if (this.socket) {
          this.socket.disconnect();
          this.socket = null;
        }
      }
    },
    selectedUnit() {
      this.getAllHistory(false);
    },
    $route(to, from) {
      if (from.name !== "unit-history" && to.name === "unit-history") {
        this.getAllHistory(false);
      }
      if (from.name === "unit-events" && to.name === "unit-history") {
        this.map.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            layer.remove();
          }
        });
      }
    }
  },

  mounted() {
    // this.getAllUnitList();

    if (this.unitDateFilter && this.unitDateFilter.selectedUnit) {
      clearTimeout(this.timeOutHistory);
      this.timeOutHistory = setTimeout(() => {
        this.getAllHistory(false);
      }, 200);
    }
  },
  methods: {
    async getAllHistory(isScroll, type) {
      try {
        this.historyNotFound = "";
        if (
          !this.unitDateFilter ||
          !this.unitDateFilter.selectedUnit ||
          !this.unitDateFilter.account_id ||
          !this.unitDateFilter.start_time ||
          !this.unitDateFilter.end_time ||
          this.unitDateFilter.start_time === "Invalid date" ||
          this.unitDateFilter.end_time === "Invalid date"
        ) {
          store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
          return;
        }
        let filters;
        if (
          this.unitDateFilter &&
          this.unitDateFilter.start_time &&
          this.unitDateFilter.end_time &&
          (this.unitDateFilter.start_time !== "Invalid date" ||
            this.unitDateFilter.end_time !== "Invalid date")
        ) {
          filters = [
            {
              field: "time",
              start_value: this.unitDateFilter.start_time,
              end_value: this.unitDateFilter.end_time,
              operator: "between"
            }
          ];
        }
        if (
          !this.unitDateFilter.selectedUnit ||
          !this.unitDateFilter.account_id
        ) {
          return;
        }
        if (this.playback) {
          this.playback.clearMap();

          this.playback.recenterMap();
        }
        store.commit("mapLoader/SET_IS_LOADING", true);
        let response = await new UnitService().getUnitHistory({
          unit_id: this.unitDateFilter.selectedUnit,
          account_id: this.unitDateFilter.account_id,
          filters,
          page: this.page,
          page_size: this.page_size
        });
        // console.log(response, type);
        if (response && response.data) {
          this.showLoading = 2;
          store.commit("mapLoader/SET_IS_LOADING", false);
          if (this.$route.name !== "unit-history") {
            return;
          }
          this.trips =
            (response.data.history && response.data.history.list) || [];

          this.unit = response.data.unit;
          this.cooards = response.data.history.list;
          if (response.data.history.list && response.data.history.list.length) {
            const unitTripsSelected = {
              unit_id: this.unitDateFilter.selectedUnit,
              id: this.unitDateFilter.selectedUnit,
              screen: "history",
              icon: this.unit.icon,
              is_live: this.isToday()
            };

            this.playback.addTrack(
              { ...unitTripsSelected, color: "blue" },
              response.data.history.list
            );

            this.playback.moveToEnd();
            this.playback.recenterMap();

            if (this.isToday()) {
              this.subscribeUnitData(this.unit, true);
            }

            this.playback.events.fire("pointClick", {
              message: {
                message_id:
                  response.data.history.list[
                    response.data.history.list.length - 1
                  ].id,
                unitId: this.unit.id
              },

              screen: "history",
              is_live: this.isToday()
            });

            // this.onHistoryClick([unitTripsSelected], this.unit);
          } else {
            this.historyNotFound = this.$t("unit.HistoryNotFound");
            this.playback.clearMap();
            this.playback.moveToEnd();
            this.playback.recenterMap();
            this.subscribeUnitData(this.unit, true);

            // this.onHistoryClick(
            //   [
            //     {
            //       coords: [],
            //       options: {
            //         color: Utils.randomDarkColor(
            //           Math.floor(Math.random() * 10) + 1
            //         )
            //       },
            //       trip_id: 1,
            //       unit_id: this.unitDateFilter.selectedUnit,
            //       index: 1,
            //       trip: "",
            //       is_live: false
            //     }
            //   ],
            //   this.unit
            // );
          }

          this.totalHistory =
            (response.data.history &&
              response.data.history.pagination &&
              response.data.history.pagination.total_records) ||
            0;
          if (this.totalHistory == 0) {
            this.showLoading = 1;
            store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
          } else {
            store.commit("mapLoader/SET_IS_POPUP_SHOW", false);
          }
        } else if (response && response.error && response.error.message) {
          store.commit("mapLoader/SET_IS_LOADING", false);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.showLoading = 1;
        }
      } catch (error) {
        store.commit("mapLoader/SET_IS_LOADING", false);
        this.showLoading = 1;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        // this.$router.push({ name: "error-404" });
      }
    },
    isToday() {
      const yourDate = moment(this.unitDateFilter.start_time);
      const currentDate = moment();
      return yourDate.isSame(currentDate, "day");
    },
    async subscribeUnitData(unit, subscribe) {
      if (!this.socket) {
        this.socket = new SocketIOService();
        await this.socket.setupSocketConnection();
      }
      if (subscribe && !unit.subscribed) {
        const posItem = unit;
        unit.subscribed = true;
        let totalDistance = 0;
        this.socket.subscribeEventWithFunction(
          `UM_${this.unitDateFilter.selectedUnit}`,
          (data) => {
            if (this.$route.name !== "unit-history") {
              return;
            }
            let lastPositionData = data.device.data.find(
              (msg) => msg.type == "GPS"
            );
            if (
              lastPositionData &&
              lastPositionData.data &&
              lastPositionData.data.latitude &&
              lastPositionData.data.longitude
            ) {
              // posItem.position.latitude = data.position.latitude;
              // posItem.position.longitude = data.position.longitude;

              lastPositionData.data.distance =
                lastPositionData.data.distance / 1000;
              const date = new Date(data.message_time);
              const isTrack = this.playback.getTrackPoint({
                id: this.unitDateFilter.selectedUnit
              });
              let lastDistance = 0;
              if (isTrack) {
                lastDistance = isTrack.getTrackDistance();
              }
              const track = {
                unit_id: this.unitDateFilter.selectedUnit,
                id: this.unitDateFilter.selectedUnit,
                screen: "history",
                icon: unit.icon,
                is_live: true
              };
              track.points = {
                id: data.device.message_id,
                latitude: lastPositionData.data.latitude,
                longitude: lastPositionData.data.longitude,
                angle: lastPositionData.data.angle,
                speed: lastPositionData.data.gps_speed,
                distance: lastDistance + lastPositionData.data.distance,
                date: date.valueOf()
              };

              if (!isTrack) {
                this.playback.addTrack({ ...track, color: "blue" }, [
                  track.points,
                  track.points
                ]);
              } else {
                const isLiveUnit = this.$store.state.mapLoader.is_live_unit;

                this.playback.addTrackPoint(track, track.points, isLiveUnit);
              }

              this.playback.events.fire("onLive", {
                ...data,
                unit_id: posItem.id
              });
            }
          }
        );
      } else if (unit.subscribed) {
        unit.subscribed = false;
        this.socket.unsubscribeEventWithFunction(`UM_${unit.id}`);
      }
    }
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
};
</script>

<style lang="scss"></style>
