<template>
  <div class="date-range-picker new-date-picker-25">
    <date-range-picker
      v-model="dateRange"
      :disabled="unit_id ? false : true"
      @update="updateValues"
      :auto-apply="true"
      :minDate="isMinDate ? getCurrentTime() : null"
      :maxDate="isMinDate ? null : getCurrentTime()"
      :ranges="ranges"
    >
      <template #input="picker">
        <span v-if="dateRange && dateRange.startDate">
          {{
            `${picker.startDate ? `${DateTimeConvert(picker.startDate)}` : ""}`
          }}
          {{
            `${picker.endDate ? ` - ${DateTimeConvert(picker.endDate)}` : " "}`
          }}
        </span>
        <span v-else class="place-holder-date">{{ $t("unit.SelectDateRange") }} </span>
      </template>
      <!--    date slot-->
      <template #date="data">
        <span class="small">{{ data.date | dateCell }}</span>
      </template>
      <!--    ranges (new slot syntax) -->
      <template #ranges="ranges">
        <div class="ranges">
          <ul>
            <li
              v-for="(range, name) in ranges.ranges"
              :key="name"
              @click="ranges.clickRange(range)"
            >
              <b>{{ name }}</b>
            </li>
          </ul>
        </div>
      </template>
      <!--    footer slot-->
    </date-range-picker>
  </div>
</template>

<script>
import constants from "@/utils/constants";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "SlotsDemo",
  components: { DateRangePicker },
  data() {
    return {
      dateRange:
        this.value && this.value.startDate
          ? { startDate: this.value.startDate, endDate: this.value.endDate }
          : { startDate: this.getCurrentTime(), endDate: this.getCurrentTime() }
    };
  },
  computed: {
    ranges() {
      if (this.selectedMenu === "services") {
        return this.getCustomFutureRanges(true);
      } else {
        return this.getRanges();
      }
    }
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  props: ["selectedMenu", "unit_id", "value", "isMinDate"],
  watch: {
    unit_id(val) {
      if (!val) {
        this.dateRange = {
          startDate: this.getCurrentTime(),
          endDate: this.getCurrentTime()
        };
        this.updateValues(this.dateRange);
      }
    }
  },
  mounted() {
    if (this.value && this.value.startDate) {
      if (this.value.startDate != "Invalid Date") {
        this.updateValues({
          startDate: new Date(this.value.startDate),
          endDate: new Date(this.value.endDate)
        });
      }
    } else {
      this.updateValues({ startDate: new Date(), endDate: new Date() });
    }
  },
  methods: {
    getRanges() {
      return this.getCustomRanges(true);
    },
    updateValues(e) {
      // Set the start date to the current time
      if (this.dateRange.startDate) {
        const startDate = moment(this.dateRange.startDate);
        const nextWeekStart = moment().add(1, "week").startOf("week");
        const nextWeekEnd = moment().add(1, "week").endOf("week");
        const isNextWeek = startDate.isBetween(
          nextWeekStart,
          nextWeekEnd,
          null,
          "[]"
        );

        const nextMonthStart = moment().add(1, "month").startOf("month");
        const nextMonthEnd = moment().add(1, "month").endOf("month");
        const isNextMonth = startDate.isBetween(
          nextMonthStart,
          nextMonthEnd,
          null,
          "[]"
        );
        if (this.selectedMenu === "services") {
          startDate.hours(moment().hours());
          startDate.minutes(moment().minutes());
          startDate.seconds(moment().seconds());
        } else {
          startDate.hours(0);
          startDate.minutes(0);
          startDate.seconds(0);
        }
        if (isNextMonth || isNextWeek) {
          startDate.hours(0);
          startDate.minutes(0);
          startDate.seconds(0);
        }

        this.dateRange.startDate = startDate;
      }

      this.dateRange.startDate = this.convertDatePerTimezone(
        this.dateRange.startDate
      );

      if (this.dateRange.endDate) {
        let endDate = moment(this.dateRange.endDate);
        const now = moment();

        const isToday = endDate.isSame(now.format(), "day");
        const isThisWeek = endDate.isSame(now.format(), "week");
        const isThisMonth = endDate.isSame(now.format(), "month");

        const yesterday = moment().subtract(1, "day").startOf("day");
        const isYesterday = endDate.isSame(yesterday, "day");

        const lastWeekStart = moment().subtract(1, "week").startOf("week");
        const lastWeekEnd = moment().subtract(1, "week").endOf("week");
        const isLastWeek = endDate.isBetween(
          lastWeekStart,
          lastWeekEnd,
          null,
          "[]"
        );

        const lastMonthStart = moment().subtract(1, "month").startOf("month");
        const lastMonthEnd = moment().subtract(1, "month").endOf("month");
        const isLastMonth = endDate.isBetween(
          lastMonthStart,
          lastMonthEnd,
          null,
          "[]"
        );

        const nextWeekStart = moment().add(1, "week").startOf("week");
        const nextWeekEnd = moment().add(1, "week").endOf("week");
        const isNextWeek = endDate.isBetween(
          nextWeekStart,
          nextWeekEnd,
          null,
          "[]"
        );

        const nextMonthStart = moment().add(1, "month").startOf("month");
        const nextMonthEnd = moment().add(1, "month").endOf("month");
        const isNextMonth = endDate.isBetween(
          nextMonthStart,
          nextMonthEnd,
          null,
          "[]"
        );

        if (isToday || isThisWeek || isThisMonth) {
          // Set current time for Today, This Week, This Month
          endDate.hours(now.hours());
          endDate.minutes(now.minutes());
          endDate.seconds(now.seconds());
        }
        if (
          this.selectedMenu === "services" &&
          (isToday || isThisWeek || isThisMonth || isNextWeek || isNextMonth)
        ) {
          // Set current time for Today, This Week, This Month
          endDate.hours(23);
          endDate.minutes(59);
          endDate.seconds(59);
        }
        if (isYesterday || isLastWeek || isLastMonth) {
          // Set to 23:59:59 for Yesterday, Last Week, Last Month
          endDate.hours(23);
          endDate.minutes(59);
          endDate.seconds(59);
        }

        this.dateRange.endDate = endDate;
      }

      this.dateRange.endDate = this.convertDatePerTimezone(
        this.dateRange.endDate
      );

      this.$emit("input", this.dateRange);
    },
    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_FORMAT);
    }
  }
};
</script>

<style lang="scss">
.slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
.date-range-picker {
  .reportrange-text {
    border: 1px solid #d8d6de !important;
  }
}
// .reportrange-text {
//   background: transparent !important;
//   padding: 9px 10px !important;
//   border: 1px solid #d8d6de !important;
//   margin-bottom: 10px;
// }
// .daterangepicker.openscenter {
//   left: 23px !important;
// }
// @media only screen and (max-width: 1024px) {
//   .daterangepicker.openscenter {
//     left: 50% !important;
//     .calendars {
//       overflow-y: auto;
//       max-height: 40vh;
//     }
//   }
//   .daterangepicker .drp-selected {
//     font-size: 9px;
//   }
// }
</style>
<style lang="scss">
.new-date-picker-25 {
  .daterangepicker.ltr.show-calendar.single.openscenter.linked {
    left: 0 !important;
    transform: translate(0%) !important;
    flex-wrap: nowrap !important;
  }
  .calendars {
    max-height: 60vh;

    flex-wrap: nowrap !important;
  }
  .calendars-container {
    // flex-wrap: nowrap !important;
  }
}
</style>
