<template>
  <div
    class="activity-list-scroll"
    ref="scrollContainer"
    @scroll="handleScroll"
  >
    <div class="table-responsive" v-if="showLoading == 0">
      <b-skeleton-table
        :rows="6"
        :columns="2"
        :table-props="{ bordered: true, striped: true }"
      />
    </div>
    <div v-if="showLoading == 1">
      <b-jumbotron :lead="$t('unit.NoActivityTxt')"> </b-jumbotron>
    </div>
    <div v-if="showLoading == 2">
      <div
        v-for="(activity, key) in activityData"
        :key="key"
        class="d-flex justify-content-between align-items-center b-card unit-ls-data"
      >
        <b-media no-body class="media-card">
          <b-media-aside class="mr-75 marginLeftImg">
            <b-avatar
              circle
              size="45"
              variant="light-primary"
              :text="
                activity &&
                activity.user &&
                activity.user.first_name &&
                activity.user.first_name.substring(0, 1) +
                  (activity &&
                  activity.user &&
                  activity.user.last_name &&
                  activity.user.last_name
                    ? activity.user.last_name.substring(0, 1)
                    : '')
              "
              :src="activity && activity.user && activity.user.avtar"
            />
          </b-media-aside>
          <b-media-body class="my-auto mb-body cursor-pointer">
            <span class="d-flex">
              <h6 class="mb-0 marginLeftImg text-col-unit">
                {{ activity.name }}
              </h6>
            </span>
            <div class="unit-account-name">
              {{
                `${
                  activity.time
                    ? `${DateTimeConvert(
                        activity.time,
                        constants.DATE_TIME_FORMAT
                      )}`
                    : " "
                }`
              }}
            </div>
            <div class="unit-account-info">
              <span
                v-if="activity.description"
                class="justify-content-end info-icon-activity"
                v-b-tooltip.hover.top="activity.description"
                :title="activity.description"
              >
                <feather-icon class="text-primary" icon="InfoIcon" size="15" />
              </span>
            </div>
          </b-media-body>
        </b-media>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BMedia,
  BMediaBody,
  BAvatar,
  BLink,
  BBadge,
  VBTooltip,
  BMediaAside,
  BSkeletonTable,
  BJumbotron,
  BRow,
  BContainer,
  BCol
} from "bootstrap-vue";
import store from "@/store";
import UnitService from "@/libs/api/unit-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import constants from "@/utils/constants";
export default {
  components: {
    BMedia,
    BMediaBody,
    BAvatar,
    BLink,
    BBadge,
    BMediaAside,
    VBTooltip,
    BSkeletonTable,
    BJumbotron,
    BRow,
    BContainer,
    BCol
  },
  data() {
    return {
      items: [],
      activityData: null,
      activityData1: [],
      totalActivity: 0,
      filter: "",
      url: process.env.VUE_APP_ICON_URL,
      showLoading: 1,
      page: 1,
      page_size: 10
    };
  },
  props: ["unitDateFilter", "selectedMenu"],
  watch: {
    unitDateFilter: {
      deep: true,
      handler(newOptions) {
        this.page = 1;
        this.page_size = 10;
        this.getAllActivities(false);
      }
    },
    selectedMenu(val) {
      if (val === "activity") {
        this.page = 1;
        this.page_size = 10;
        this.getAllActivities();
      }
    }
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  setup() {},
  computed: {},

  beforeDestroy() {},
  mounted() {
    this.getAllActivities(false);
  },
  methods: {
    handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollOffset =
        scrollContainer.scrollHeight - scrollContainer.scrollTop - 1;
      const containerHeight = scrollContainer.clientHeight;
      if (scrollOffset > containerHeight) {
        if (this.totalActivity > this.page * this.page_size) {
          this.page++;
          this.getAllActivities(true);
        }
      }
    },

    async getAllActivities(isScroll) {
      try {
        store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
        if (
          !this.unitDateFilter ||
          !this.unitDateFilter.selectedUnit ||
          !this.unitDateFilter.account_id ||
          !this.unitDateFilter.start_time ||
          !this.unitDateFilter.end_time ||
          this.unitDateFilter.start_time === "Invalid date" ||
          this.unitDateFilter.end_time === "Invalid date"
        ) {
          store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
          return;
        }
        let filters;
        if (
          this.unitDateFilter &&
          this.unitDateFilter.start_time &&
          this.unitDateFilter.end_time &&
          (this.unitDateFilter.start_time !== "Invalid date" ||
            this.unitDateFilter.end_time !== "Invalid date")
        ) {
          filters = [
            {
              field: "time",
              start_value: this.unitDateFilter.start_time,
              end_value: this.unitDateFilter.end_time,
              operator: "between"
            }
          ];
        }
        if (
          !this.unitDateFilter.selectedUnit ||
          !this.unitDateFilter.account_id
        ) {
          return;
        }
        if (!isScroll) {
          this.showLoading = 0;
        }

        let requestData = {
          page: this.page,
          page_size: this.page_size,
          filters,
          unit_id: this.unitDateFilter.selectedUnit,
          account_id: this.unitDateFilter.account_id
        };
        let response = await new UnitService().getAllActivities(requestData);

        if (response && response.data && response.data.activities) {
          this.showLoading = 2;
          const activity =
            (response.data.activities && response.data.activities.list) || [];
          this.activityData = isScroll
            ? [...this.eventData, ...activity]
            : activity;

          this.totalActivity =
            (response.data.activities &&
              response.data.activities.pagination &&
              response.data.activities.pagination.total_records) ||
            0;
          if (this.totalActivity == 0) {
            this.showLoading = 1;
          }
          if (this.clearPage) {
            this.$router.replace({ query: {} });
          }
        } else if (response && response.error && response.error.message) {
          this.showLoading = 1;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = 1;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    DateTimeConvert(date, format) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    }
  }
};
</script>

<style lang="scss">
@import "./../index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.unit-ls-data {
  box-shadow: none;
  border: 1px solid var(--gray-605);
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 12px;
  position: relative;
  .media-aside {
    .b-avatar {
      .b-avatar-img {
        padding: 0px;
        img {
          object-fit: contain;
        }
      }
    }
  }
  .b-avatar.badge-secondary {
    background-color: var(--gray-605);
    border-radius: 50% !important;
    margin-left: 6px;
  }
  .no-gsm-text {
    padding-top: 0px;
    margin-left: 10px;
    margin-right: 4px;
    .signal-icon.mr-75 {
      margin-right: 0px !important;
    }
    .gsm-text {
      display: none;
    }
  }
  .media-card {
    width: 100%;
    padding-top: 0px;
  }
  .unit-account-name {
    font-size: 10px;
    margin-top: 4px;
  }
  .unit-account-info {
    text-align: right;
    position: relative;
    .info-icon-activity {
      position: absolute;
      right: 0;
      bottom: -10px;
    }
  }
}
.dark-layout {
  .unit-ls-data {
    .b-avatar.badge-secondary {
      background-color: var(--light);
    }
  }
}
.activity-list-scroll {
  min-height: calc(100vh - 380px);
  max-height: calc(100vh - 380px);
  overflow-y: auto;
  margin-top: 15px;
}
// @media screen and (max-width: 991px) {
//   .activity-list-scroll {
//     min-height: calc(100vh - 345px);
//     max-height: calc(100vh - 345px);
//   }
// }
@media screen and (max-width: 667px) {
  .activity-list-scroll {
    min-height: calc(100vh - 275px);
    max-height: calc(100vh - 275px);
  }
}
</style>
