<template>
  <div
    style="min-height: 180px"
    :class="{
      'outer-info': true,
      'sidebar-close': !sidebarToggle,
      'sidebar-open': !!sidebarToggle,
      'event-modal': true
    }"
  >
    <div class="event-popover">
      <button
        type="button"
        aria-label="Close"
        @click="clearEventInfo"
        class="close"
      >
        &times;
      </button>

      <div v-html="eventInfo.display_card"></div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  VBTooltip,
  BTooltip,
  BLink,
  BButton
} from "bootstrap-vue";
import Badge from "@/layouts/components/Badge.vue";

import UnitPlayBack from "./UnitPlayBack";
import CallIcon from "@/assets/images/unit-icon/call.svg";
import AddressIcon from "@/assets/images/unit-icon/address-icon.svg";
import constants from "@/utils/constants";
export default {
  components: {
    BAvatar,
    BCard,
    Badge,
    VBTooltip,
    BLink,
    BTooltip,
    CallIcon,
    AddressIcon,
    BButton,
    UnitPlayBack
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      isHideMenu: false,
      isOnlyPlayBack: false
    };
  },

  props: ["eventInfo", "clearEventInfo", "sidebarToggle"],
  mounted() {},
  computed: {},
  methods: {
    DateTimeConvert(date, format) {
      if (!date) {
        return "";
      }
      return this.formattedDateTime(date, constants.DATE_FORMAT);
    }
  }
};
</script>

<style lang="scss" scoped>
.no-wp-txt {
  white-space: nowrap;
  font-size: 11px;
}
.outer-info .close {
  right: 2px;
}
</style>
<style lang="scss">
.dark-layout {
  .event-modal .tooltip-popover {
    background: var(--dark46);
  }
  .event-modal .line-information {
    color: white !important;
  }
  .event-modal .time-outer {
    color: white !important;
  }
}
.tooltip-popover {
  display: block;
  max-width: calc(100% - 36px) !important;
  // max-width: 95% !important;
  // min-width: 95% !important;
  left: 15px;
}
.event-modal {
  &::after {
    display: none;
  }
}
@media only screen and (max-width: 820px) {
  .event-modal {
    height: auto !important;
    top: auto !important;
    bottom: 0px !important;
    left: -9px !important;
  }
  .event-popover {
    position: absolute;
    width: 100%;
    bottom: 5px;
    z-index: 90;
    left: 0;
    height: auto;
    right: 0;
    .tooltip-popover {
      position: relative;
    }
    .close {
      right: 18px !important;
    }
  }
}
</style>
