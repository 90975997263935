import L from "leaflet";

export var TrackController = L.Class.extend({
  initialize: function (tracks, draw, options) {
    L.setOptions(this, options);

    this._tracks = [];

    this._minTime = null;
    this._maxTime = null;
    this._lastLiveTrack = null;
    this._maxDistance = 0;

    this._draw = draw;

    if (tracks && tracks.length) {
      for (let i = 0, len = tracks.length; i < len; i++) {
        this._tracks.push(tracks[i]);
      }
    }

    this._update();

    this._calculateCount();
  },

  getMinTime: function () {
    return this._minTime;
  },

  getMaxTime: function () {
    return this._maxTime;
  },
  getLiveLastTrack: function () {
    return this._lastLiveTrack;
  },
  getMaxDistance: function () {
    return this._maxDistance;
  },
  addTrack: function (track) {
    if (track) {
      this._tracks.push(track);
      this._update();
    }
  },
  findTrack(id) {
    return this._tracks.find((track) => {
      return track.id == id;
    });
  },
  removeTrack(id) {
    for (let i = 0; i < this._tracks.length; i++) {
      if (this._tracks[i].id == id) {
        this._tracks.splice(i, 1);
        i--;
      }
    }
  },
  clearTracks() {
    this._tracks = [];
    this._update();
  },
  getTrackPointsByTime(time) {
    let tps = [];
    for (let i = 0, len = this._tracks.length; i < len; i++) {
      let track = this._tracks[i];
      tps = tps.concat(track.getTrackPointsBeforeTime(time));
    }
    return tps;
  },
  getNextTime(time) {
    for (const t of this._tracks.map((track) =>
      Number(track.getNextTime(time))
    )) {
      if (t > time) {
        return t;
      }
    }
    return time;
  },
  drawTracksByTime: function (time) {
    this._draw.clear();
    let count = 0;
    for (let i = 0, len = this._tracks.length; i < len; i++) {
      let track = this._tracks[i];
      const tps = track.getTrackPointsBeforeTime(time);
      if (tps && tps.length) {
        count += tps.length;
        this._draw.drawTrack(tps);
      }
    }
    return count;
  },
  getAllBounds() {
    return this._allBounds;
  },
  getBoundsAtTime(time) {
    time = parseInt(time);
    let bounds = L.latLngBounds();
    for (let i = 0, len = this._tracks.length; i < len; i++) {
      let track = this._tracks[i];
      const point = track.getTrackPointByTime(time);
      if (point) {
        bounds.extend([point.lat, point.lng]);
      }
    }
    return bounds;
  },
  getNow() {
    parseInt(new Date().getTime() / 1000);
  },
  _update: function () {
    this._allBounds = L.latLngBounds();
    if (this._tracks.length) {
      this._minTime = this._tracks[0].getStartTrackPoint().time;
      this._maxTime = this._tracks[0].getEndTrackPoint().time;
      this._lastLiveTrack = this._tracks[0].getLastLiveTrack();
      this._maxDistance = this._tracks[0].getEndTrackPoint().message.distance;
      for (let i = 0, len = this._tracks.length; i < len; i++) {
        let stime = this._tracks[i].getStartTrackPoint().time;
        let etime = this._tracks[i].getEndTrackPoint().time;
        if (stime < this._minTime) {
          this._minTime = stime;
        }
        if (etime > this._maxTime) {
          this._maxTime = etime;
        }
        for (const point of this._tracks[i]._trackPoints) {
          this._allBounds.extend([point.lat, point.lng]);
        }
      }
    } else {
      this._minTime = this._maxTime = this.getNow();
    }
  },

  _calculateCount: function () {
    var shipCount = this._tracks.length;
    var pointCount = 0;
    for (let i = 0, len = shipCount; i < len; i++) {
      pointCount += this._tracks[i]._trackPoints.length;
    }
  },

  addEventMarker(e) {
    return this._draw.addEventMarker(e);
  }
});

export var trackController = function (tracks, draw, options) {
  return new TrackController(tracks, draw, options);
};
