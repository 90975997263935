var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"scrollContainer",staticClass:"activity-list-scroll",on:{"scroll":_vm.handleScroll}},[(_vm.showLoading == 0)?_c('div',{staticClass:"table-responsive"},[_c('b-skeleton-table',{attrs:{"rows":6,"columns":2,"table-props":{ bordered: true, striped: true }}})],1):_vm._e(),(_vm.showLoading == 1)?_c('div',[_c('b-jumbotron',{attrs:{"lead":_vm.$t('unit.NoActivityTxt')}})],1):_vm._e(),(_vm.showLoading == 2)?_c('div',_vm._l((_vm.activityData),function(activity,key){return _c('div',{key:key,staticClass:"d-flex justify-content-between align-items-center b-card unit-ls-data"},[_c('b-media',{staticClass:"media-card",attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-75 marginLeftImg"},[_c('b-avatar',{attrs:{"circle":"","size":"45","variant":"light-primary","text":activity &&
              activity.user &&
              activity.user.first_name &&
              activity.user.first_name.substring(0, 1) +
                (activity &&
                activity.user &&
                activity.user.last_name &&
                activity.user.last_name
                  ? activity.user.last_name.substring(0, 1)
                  : ''),"src":activity && activity.user && activity.user.avtar}})],1),_c('b-media-body',{staticClass:"my-auto mb-body cursor-pointer"},[_c('span',{staticClass:"d-flex"},[_c('h6',{staticClass:"mb-0 marginLeftImg text-col-unit"},[_vm._v(" "+_vm._s(activity.name)+" ")])]),_c('div',{staticClass:"unit-account-name"},[_vm._v(" "+_vm._s(("" + (activity.time ? ("" + (_vm.DateTimeConvert( activity.time, _vm.constants.DATE_TIME_FORMAT ))) : " ")))+" ")]),_c('div',{staticClass:"unit-account-info"},[(activity.description)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(activity.description),expression:"activity.description",modifiers:{"hover":true,"top":true}}],staticClass:"justify-content-end info-icon-activity",attrs:{"title":activity.description}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"InfoIcon","size":"15"}})],1):_vm._e()])])],1)],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }