import L, { latLng } from "leaflet";
// import $ from "jquery";
import { PlayBack, playback } from "./leaflet.playback/index";
import "leaflet.markercluster";

import "../css/control.playback.css";
import i18n from "@/libs/i18n";
const language = localStorage.getItem("lang") || "en";
const recenterMapTitle = i18n?.messages?.[language]?.RecenterMap
  ? i18n?.messages?.[language]?.RecenterMap
  : "Recenter Map";
L.Control.PlayBack = L.Control.extend({
  options: {
    position: "topleft",
    data: {}
  },

  initialize: function (options) {
    L.Control.prototype.initialize.call(this, options);
    this._data = (this.options && this.options.data) || [];
    this._playback = {};
    this.events = new L.Evented();
  },

  onAdd: function (map) {
    this._initLayout();
    this._update();
    return this._container;
  },

  onRemove: function (map) {
    if (this._playback && this._playback.draw) {
      this._playback.draw.removeLayer();
    }
  },
  toggleRecenter(toggle) {
    this.recenterEnabled =
      toggle && this.recenterEnabled
        ? this.recenterEnabled
        : !this.recenterEnabled;
    if (this.recenterEnabled) {
      this.recenterButton.innerHTML = `<img class="playback-recenter" src="/app/images/crosshair.png" />`;
      this.recenterMap();
    } else {
      this.recenterButton.innerHTML = `<img class="playback-recenter" src="/app/images/crosshair-off.png">`;
    }
  },
  _initLayout: function () {
    // Create a container div for the control
    this._container = L.DomUtil.create("div", "leaflet-bar leaflet-control");

    // Create the recenter button
    this.recenterButton = L.DomUtil.create(
      "a",
      "leaflet-control-recenter leaflet-bar-part",
      this._container
    );
    this.recenterButton.href = "#";
    this.recenterButton.title = recenterMapTitle;
    this.toggleRecenter();
    this.recenterButton.addEventListener("click", (e) => {
      this.toggleRecenter();
    });
    this._map.on("zoomend", (e) => {
      if (this.userInteracted) {
        this.recenterEnabled = true;
        this.toggleRecenter();
      }
      this.userInteracted = false;
    });
    this._map.on("moveend", (e) => {
      if (this.userInteracted) {
        this.recenterEnabled = true;
        this.toggleRecenter();
      }
      this.userInteracted = false;
    });
    this._map.on("mousedown", (e) => {
      if (
        e?.originalEvent?.target &&
        e.originalEvent.target.classList.contains("playback-recenter")
      ) {
        return;
      }
      this.userInteracted = true;
    });

    this._map.on("wheel", (e) => {
      this.userInteracted = true;
    });
  },

  _update: function () {
    var map = this._map;
    var data = this._dataTransform(this._data.units);
    if (map && data) {
      var tracks = [];
      for (var i = 0, len = data.length; i < len; i++) {
        var track = new PlayBack.Track(data[i], this.options);
        tracks.push(track);
      }
      this._playback.draw =
        this._playback.draw ||
        new PlayBack.Draw(map, this.options, this.events);
      this._playback.trackController = new PlayBack.TrackController(
        tracks,
        this._playback.draw,
        this.options
      );
      this._playback.clock = new PlayBack.Clock(
        this._playback.trackController,
        this._clockCallback.bind(this),
        map,
        this.options
      );
      this._playback.clock.setCursor(this.getStartTime());
    }
  },

  getStartTime: function () {
    if (this._playback?.clock) {
      return this._playback.clock.getStartTime();
    }
    return 1;
  },

  getEndTime: function () {
    if (this._playback?.clock) {
      return this._playback.clock.getEndTime();
    }
    return 1;
  },

  getCurTime: function () {
    if (this._playback?.clock) {
      return this._playback.clock.getCurTime();
    }
    return 1;
  },

  _clockCallback: function (s) {
    const lastTrack = this._playback.trackController.getLiveLastTrack();
    if (s >= this.getEndTime() && lastTrack && !lastTrack.isLive) {
      this._playback.clock.stop();
      this.events.fire("stop");
    }
    const tracks = this._playback.trackController.getTrackPointsByTime(s);
    if (tracks && tracks.length) {
      for (let i = tracks.length - 1; i >= 0; i--) {
        if (tracks[i].isOrigin) {
          this.events.fire("tick", tracks[i]);
          break;
        }
      }
    }
    if (this.recenterEnabled) {
      this.recenterMap();
    }
  },

  _formatPositionData(unit, item) {
    if (item.event_name) {
      return {
        lng: Number(item.longitude),
        lat: Number(item.latitude),
        time: new Date(item.date).getTime() / 1000,
        dir: parseFloat(item.angle),
        heading: parseFloat(item.angle),
        screen: "event",
        display_card: item.display_card,

        message: {
          ...item,
          unitId: unit.unit_id
        },
        info: [
          { key: "ID", value: item.id },
          { key: "Event Name", value: item.event_name },
          { key: "Latitude", value: parseFloat(item.latitude).toFixed(6) },
          { key: "Longitude", value: parseFloat(item.longitude).toFixed(6) },
          {
            key: "Address",
            value: `<a href=${item.address}>${item.address}</a>`
          },

          {
            key: "Time",
            value: new Date(item.date).toLocaleString()
          }
        ]
      };
    } else {
      return {
        lng: Number(item.longitude),
        lat: Number(item.latitude),
        time: new Date(item.date).getTime() / 1000,
        dir: parseFloat(item.angle),
        heading: parseFloat(item.angle),
        screen: unit.screen,
        is_live: unit.is_live,
        message: {
          ...item,
          unitId: unit.unit_id,
          tripId: unit.screen === "trip" && unit.trip_id ? unit.trip_id : ""
        },
        info: [
          { key: "ID", value: item.id },
          { key: "Heading", value: parseInt(item.angle) },
          { key: "Latitude", value: parseFloat(item.latitude).toFixed(6) },
          { key: "Longitude", value: parseFloat(item.longitude).toFixed(6) },
          { key: "Speed", value: parseInt(item.speed) },
          { key: "Distance", value: parseFloat(item.distance).toFixed(1) },
          {
            key: "Time",
            value: new Date(item.date).toLocaleString()
          }
        ]
      };
    }
  },
  _dataTransform: function (units) {
    if (!units || !units.length) {
      // console.log("playback_error:data transform error!");
      return;
    }
    return units.map((unit) => {
      const u = {
        id: unit.id,
        name: unit.name,
        icon: unit.icon,
        color: unit.color,
        screen: unit.screen,
        timePosList: (unit.points || []).map((item) => {
          return this._formatPositionData(unit, item);
        })
      };
      return u;
    });
  },
  _updateClock() {
    this._playback.trackController._update();
    this._playback.clock._update();
    // this.setTime();
  },
  getTrackPoint(unit) {
    const track = this._playback.trackController.findTrack(unit.id);
    return track;
  },
  addTrackPoint(unit, point, isLiveUnit) {
    const track = this._playback.trackController.findTrack(unit.id);
    if (track) {
      track.addTrackPoint(this._formatPositionData(unit, point));
      this._updateClock();
      if (isLiveUnit) {
        this._playback.clock.start();
      }
    }
  },
  removeTrack(unit) {
    if (this._playback && this._playback.trackController) {
      this._playback.trackController.removeTrack(unit.id);
      this._updateClock();
    }
    this.events.fire("timeUpdated", {
      startTime: this.getStartTime(),
      endTime: this.getEndTime()
    });
    this.events.fire("removeTrack");
  },
  recenterMap() {
    if (this._playback.draw && this._playback.clock) {
      const isPlaying = this._playback.clock.isPlaying();
      const time = this._playback.clock.getCurTime();
      let bounds;
      const lastTrack = this._playback.trackController.getLiveLastTrack();

      if (isPlaying || lastTrack.is_live) {
        bounds = this._playback.trackController.getBoundsAtTime(time);
      } else {
        bounds = this._playback.trackController.getAllBounds();
      }
      this._playback.draw.recenterMap(bounds);
    }
  },
  addTrack(unit, points = []) {
    if (!this._playback.trackController) {
      this._data.units = [{ ...unit, points }];
      this._update();
      return;
    }
    const track = new PlayBack.Track(
      this._dataTransform([{ ...unit, points }])[0],
      this.options
    );
    this._playback.trackController.addTrack(track);
    this._updateClock();
    this.events.fire("timeUpdated", {
      startTime: this.getStartTime(),
      endTime: this.getEndTime()
    });
    this.events.fire("addTrack", track);
  },
  moveToEnd() {
    if (this._playback?.clock) {
      const time = this.getEndTime();
      this._playback.clock.setCursor(time);
      this.events.fire("stop");
      this._clockCallback(time);
    }
  },
  moveToEndWithOutStop() {
    if (this._playback?.clock) {
      const time = this.getEndTime();
      this._playback.clock.setCursor(time);
      // this.events.fire("stop");
      this._clockCallback(time);
    }
  },
  setSpeed(speed) {
    if (this._playback.clock) {
      this._playback.clock.setSpeed(speed);
    }
  },
  stop() {
    this.moveToEnd();
    this.events.fire("stop");
  },
  pause() {
    if (this._playback && this._playback.clock) {
      this._playback.clock.stop();
    }
    this.events.fire("pause");
  },
  seek(value) {
    if (this._playback) {
      this._playback.clock.setCursor(value);
    }
  },
  isPlaying() {
    if (this._playback) {
      return this._playback.clock.isPlaying() == 1;
    }
    return false;
  },
  play() {
    if (this._playback) {
      const curTime = this._playback.clock.getCurTime();
      const maxTime = this._playback.clock.getEndTime();
      if (curTime >= maxTime) {
        this._playback.clock.rePlaying();
      } else {
        this._playback.clock.start();
      }
    }

    this.events.fire("play");
  },
  clearMap() {
    if (this._playback) {
      if (this._playback.clock) {
        this._playback.clock.stop();
      }
      if (this._playback.trackController) {
        this._playback.trackController.clearTracks();
      }
    }
    this.events.fire("clearMap");
  },
  getTrackCount() {
    if (this._playback?.trackController?._tracks) {
      return this._playback.trackController._tracks.length;
    }
    return 0;
  },
  getMap() {
    return this._map;
  },

  addMarker() {
    if (!this._playback.draw) {
      this._playback.draw = new PlayBack.Draw(
        this._map,
        this.options,
        this.events
      );
    }
    return this._playback.draw.addMarker(...arguments);
  },
  removeUnitMarker() {
    if (!this._playback.draw) {
      this._playback.draw = new PlayBack.Draw(
        this._map,
        this.options,
        this.events
      );
    }
    return this._playback.draw.removeUnitMarker(...arguments);
  },
  updateUnitMarker() {
    if (!this._playback.draw) {
      this._playback.draw = new PlayBack.Draw(
        this._map,
        this.options,
        this.events
      );
    }
    return this._playback.draw.updateUnitMarker(...arguments);
  },
  getUnitMarker() {
    if (!this._playback.draw) {
      this._playback.draw = new PlayBack.Draw(
        this._map,
        this.options,
        this.events
      );
    }
    return this._playback.draw.getUnitMarker(...arguments);
  },
  addUnitMarker() {
    if (!this._playback.draw) {
      this._playback.draw = new PlayBack.Draw(
        this._map,
        this.options,
        this.events
      );
    }
    return this._playback.draw.addUnitMarker(...arguments);
  },
  clearAllMarkers() {
    try {
      return this._playback.draw.clearAllMarkers(...arguments);
    } catch (err) {}
  },
  highLightMarker() {
    try {
      return this._playback.draw.highLightMarker(...arguments);
    } catch (err) {}
  }
});

L.control.playback = function (options) {
  return new L.Control.PlayBack(options);
};
